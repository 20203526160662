import React, { useState, useEffect } from 'react';
import "./MainContent.css";
import "./Overlay.css";
import MapSelection from "../MapSelection/MapSelection";
import QuestList from '../QuestList/QuestList';
import LevelInput from "../LevelInput/LevelInput";
import MapDisplay from "../MapDisplay/MapDisplay";
import Settings from "../Settings/Settings";
import NeededKeys from "../NeededKeys/NeededKeys";
import NeededItems from "../NeededItems/NeededItems"
import { useUser } from "../../contexts/UserContext";
import { useQuests } from "../../contexts/QuestContext";
import { useMap } from "../../contexts/MapContext";
import NavigationBar from "../NavigationBar/NavigationBar";
import KQlogo from "../../static/images/logos/KQlogo.png";

function MainContent() {
  const { user, fetchUserInfo } = useUser();
  const { refreshQuests } = useQuests();
  const { selectedMap, setSelectedMap } = useMap();
  const [username, setUsername] = useState('');
  const [faction, setFaction] = useState("");
  const [level, setLevel] = useState(1);
  const [editLevel, setEditLevel] = useState(1);
  const [levelChanged, setLevelChanged] = useState(false);
  const [isMapSelectionOpen, setIsMapSelectionOpen] = useState(false);
  const [isMapDisplayOpen, setIsMapDisplayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);


   const handleAccountReset = () => {
    fetchUserInfo();
    refreshQuests();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      handleClosePopups();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const openSettings = () => setIsSettingsOpen(true);
  const closeSettings = () => setIsSettingsOpen(false);

  const handleMapSelect = (map) => {
    setSelectedMap(map);
    setIsMapDisplayOpen(true);
  };

  const handleCloseMapDisplay = () => setIsMapDisplayOpen(false);
  const handleClosePopups = () => {
    setIsMapSelectionOpen(false);
    setIsMapDisplayOpen(false);
  };

  const handleSettingsClick = () => openSettings();

  return (
    <div className="mainContentContainer">
      <header className="App-header">
        <img src={KQlogo} alt="" />Karta Quest
      </header>
      <NavigationBar
        onMapSelect={handleMapSelect}
        faction={faction}
        setFaction={setFaction}
        onAccountReset={handleAccountReset}
      />
      {(isMapSelectionOpen || isMapDisplayOpen || isSettingsOpen) && (
        <div className="overlay" onClick={handleClosePopups}></div>
      )}
      {/*<LoyaltyLevelBox />*/}
      <LevelInput
       username={username}
       userId={user.userId}
       level={level}
       setLevel={setLevel}
       editLevel={editLevel}
       setEditLevel={setEditLevel}
       setLevelChanged={setLevelChanged}
       onSettingsClick={handleSettingsClick}
      />
      <div className="contentRow">
        <NeededKeys className="neededKeys" />
        <QuestList className="questList" setIsMapSelectionOpen={setIsMapSelectionOpen} />
        <div className="neededItemsContainer neededItems">
          <h2>Quest Items</h2>
          <NeededItems className="neededItems" />
        </div>
      </div>
      {isMapSelectionOpen && <MapSelection isOpen={isMapSelectionOpen} onClose={() => setIsMapSelectionOpen(false)} onMapSelect={handleMapSelect} />}
      {isMapDisplayOpen && <MapDisplay selectedMap={selectedMap} onClose={handleCloseMapDisplay} />}
      {isSettingsOpen && <Settings setLevel={setLevel} faction={faction} setFaction={setFaction} onClose={closeSettings} onAccountReset={handleAccountReset} />}
    </div>
  );
}

export default MainContent;