import React, { useState } from 'react';
import './Info.css'
import Login from '../Login/Login';
import CreateAccount from '../CreateAccount/CreateAccount';

function Info({ setIsLoggedIn }) {
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  const handleCreateAccountClick = () => {
    setShowCreateAccount(true);
  };

  const handleAccountCreated = () => {
      setShowCreateAccount(false);
    }

  return (
    <div className="infoWindow">
      <div className="cardContainer">
        {showCreateAccount ? (
          // Display the CreateAccount component
          <CreateAccount setShowCreateAccount={setShowCreateAccount} onAccountCreated={handleAccountCreated} />
        ) : (
          <>
            <div className="leftSection">
                  <p>All objectives.</p>
                  <p><span className="bold">One</span> Karta.</p>
            </div>
            <div className="rightSection">
              <Login setIsLoggedIn={setIsLoggedIn} handleCreateAccountClick={handleCreateAccountClick}/>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Info;
