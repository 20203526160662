import React from 'react';
import './QuestCard.css';
import { useQuests } from '../../contexts/QuestContext';
import { Tooltip } from 'react-tooltip';
import TooltipPortal from '../TooltipPortal/TooltipPortal';


import praporImage from "../../static/images/traders/Prapor.PNG";
import therapistImage from "../../static/images/traders/Therapist.PNG";
import fenceImage from "../../static/images/traders/Fence.PNG";
import skierImage from "../../static/images/traders/Skier.PNG";
import peacekeeperImage from "../../static/images/traders/Peacekeeper.PNG";
import mechanicImage from "../../static/images/traders/Mechanic.PNG";
import ragmanImage from "../../static/images/traders/Ragman.PNG";
import jaegerImage from "../../static/images/traders/Jaeger.PNG";
import lightkeeperImage from "../../static/images/traders/Lightkeeper.png";

function QuestCard({ questId }) {
  const { questDetails, handleCompleteQuest } = useQuests(); // Access questDetails from context
  const questData = questDetails[questId] || {};
  const tooltipId = `tooltip-locations-${questId}`;

  const completeQuest = () => {
    handleCompleteQuest(questId).then(() => {
    });
  };

  const getTraderImage = traderName => {
    const traderImageMapping = {
      'Prapor': praporImage,
      'Therapist': therapistImage,
      'Fence': fenceImage,
      'Skier': skierImage,
      'Peacekeeper': peacekeeperImage,
      'Mechanic': mechanicImage,
      'Ragman': ragmanImage,
      'Jaeger': jaegerImage,
      'Lightkeeper': lightkeeperImage,
    };
    return traderImageMapping[traderName];
  };

  const getUniqueObjectiveLocations = () => {
    const locations = questData.objectives?.map(objective => objective.ObjectiveMap);
    const uniqueLocations = [...new Set(locations)];

    if (uniqueLocations.length > 3) {
      return {
        text: "Multiple locations",
        tooltip: uniqueLocations.join('\n'),
        showTooltip: true
      };
    }
    return { text: uniqueLocations.join(', '), tooltip: '', showTooltip: false };
  };

  const locationInfo = getUniqueObjectiveLocations();


  return (
    <div className="questCardContainer">
      <div className="trader">
        <img src={getTraderImage(questData.GivenBy)} alt={questData.GivenBy} />
      </div>
      <div className="task">{questData.QuestName || 'Quest Name'}</div>
      <div
        className={`locationCard${locationInfo.showTooltip ? ' with-tooltip' : ''}`}
        data-tooltip-id={locationInfo.showTooltip ? tooltipId : ''}
      >
        {locationInfo.text}
        {locationInfo.showTooltip && (
          <TooltipPortal>
            <Tooltip
              id={tooltipId}
              className="custom-tooltip"
              style={{
                backgroundColor: 'black',
                borderRadius: '5px',
                '--rt-opacity': 1,
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              <div style={{ whiteSpace: 'pre-line' }}>{locationInfo.tooltip}</div>
            </Tooltip>
          </TooltipPortal>
        )}
      </div>
      <div className="description">{questData.Description || 'Description placeholder'}</div>
        <button className="complete-button" onClick={completeQuest}>
          &#x2713;
        </button>
      </div>
    );
  }

export default React.memo(QuestCard);
