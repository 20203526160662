import React from 'react';
import './App.css';
import MainContent from "./components/MainContent/MainContent";
import BugReport from './components/BugReport/BugReport';
import Info from "./components/Info/Info";
import { UserProvider, useUser } from './contexts/UserContext';
import { QuestProvider } from './contexts/QuestContext';
import { MapProvider } from "./contexts/MapContext";
import { ModalProvider } from './contexts/ModalContext';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HideoutProvider} from "./contexts/HideoutContext";





function AppWrapper() {
  return (
    <HideoutProvider>
      <ModalProvider>
        <MapProvider>
          <UserProvider>
            <QuestProvider>
              <App />
            </QuestProvider>
          </UserProvider>
        </MapProvider>
      </ModalProvider>
    </HideoutProvider>
  );
}

function App() {
  const { isLoggedIn } = useUser();

  return (
    <div className="App">
      <BugReport />
      {isLoggedIn ? <MainContent /> : <Info />}
    </div>
  );
}

export default AppWrapper;
