import React from 'react';
import './QuestsModal.css';
import { useQuests } from "../../contexts/QuestContext";
import { useMap } from "../../contexts/MapContext"
import QuestsModalListItem from "../QuestsModalListItem/QuestsModalListItem";


function QuestsModal({ isOpen, selectedMap }) {
  const { questDetails } = useQuests();
  const {  hiddenPaths, setHiddenPaths } = useMap();

  if (!isOpen) return null;
 // Convert the questDetails object into an array of its values (the individual quests)
  const questsArray = Object.values(questDetails);

  // Filter quests that have an objective with a map that matches the selectedMap
  const filteredQuests = questsArray.filter((quest) =>
    quest.objectives && quest.objectives.some((objective) => objective.ObjectiveMap === selectedMap)
  );



  const handleButtonClick = (quest) => {
    setHiddenPaths((prevState) => {
      const currentState = Array.isArray(prevState) ? prevState : [];
      const questSVGPaths = quest.objectives
        .filter((objective) => objective.SVGPath)
        .map((objective) => objective.SVGPath);

      if (questSVGPaths.length === 0) {
        return currentState;
      }

      const isQuestHidden = questSVGPaths.some(path => currentState.includes(path));

      if (isQuestHidden) {
        return currentState.filter(path => !questSVGPaths.includes(path));
      } else {
        const newPaths = questSVGPaths.filter(path => !currentState.includes(path));
        return [...currentState, ...newPaths];
      }
    });
  };


  return (

    <div className="questsModal">
      <div className="questsModalHeader">
        <span>Quests</span>
      </div>
      <div className="questsModalContent">
        <ul>
          {filteredQuests.map((quest) => (
            <QuestsModalListItem
              key={quest.QuestName}
              quest={quest}
              handleButtonClick={handleButtonClick}
              hiddenPaths={hiddenPaths}
              selectedMap={selectedMap}
            />
          ))}
          {filteredQuests.length === 0 && <li>No quests available for this map</li>}
        </ul>
      </div>
    </div>

  );
}

export default QuestsModal;


