import React, { useState, useEffect } from 'react';
import './BugReport.css';
import html2canvas from 'html2canvas';
import { useUser } from "../../contexts/UserContext";


function BugReport() {
  const { user, setUser } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [includeScreenshot, setIncludeScreenshot] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);






  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true); // Indicate that submission is in progress
    closeModal(); // Close the modal
  };

  useEffect(() => {
  const submitReport = async () => {
    if (isSubmitting && !isModalOpen) {
      try {
        let screenshotBlob = null;

        // If a screenshot is to be included, capture it
        if (includeScreenshot) {
          screenshotBlob = await captureScreenshot();
        }

        // Create the Trello card
        const card = await createTrelloCard(description, includeScreenshot);
        const cardId = card.id;

        // If a screenshot was captured, attach it to the card
        if (screenshotBlob) {
          await attachScreenshotToCard(cardId, screenshotBlob);
        }

        setSubmissionSuccess(true);

      } catch (error) {
        console.error('Error submitting bug report:', error);
        // Handle error, e.g., displaying an error message to the user
      } finally {
        setIsSubmitting(false); // Reset the submission flag
      }
    }
  };

  submitReport();
}, [isSubmitting, isModalOpen, includeScreenshot, description]);



  const createTrelloCard = async (description, includeScreenshot) => {

    const key = '76929ea3dd3fd3a26ea9e06d1697c963';
    const token = 'ATTAf4ab9212c61cd64dbb54046f3685389cf85f73e4eb7210f3ce8acf92ddcbfd6726D00052';
    const listId = '65b2a5997ac9bebd546ae4b1'; // ID of the "User Bug Reports" list

    // Construct the card title and description
    const name = `Bug Report from ${user?.Login || 'Guest'}`;
    let desc = description;

    const url = `https://api.trello.com/1/cards?idList=${listId}&key=${key}&token=${token}&name=${encodeURIComponent(name)}&desc=${encodeURIComponent(desc)}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      return response.json();
    } catch (error) {
      console.error('Error creating Trello card:', error);
    }
  };

  const captureScreenshot = () => {
    return new Promise((resolve, reject) => {
      html2canvas(document.body).then(canvas => {
        canvas.toBlob(blob => {
          resolve(blob);
        }, 'image/png');
      }).catch(error => {
        reject(error);
      });
    });
  };

  const attachScreenshotToCard = async (cardId, screenshotBlob) => {
    const formData = new FormData();
    formData.append('file', screenshotBlob, 'screenshot.png');

    const key = '76929ea3dd3fd3a26ea9e06d1697c963';
    const token = 'ATTAf4ab9212c61cd64dbb54046f3685389cf85f73e4eb7210f3ce8acf92ddcbfd6726D00052';
    const url = `https://api.trello.com/1/cards/${cardId}/attachments?key=${key}&token=${token}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      return response.json();
    } catch (error) {
      console.error('Error attaching screenshot to Trello card:', error);
    }
  };



  return (
    <>
      <button className={`bug-report-button ${submissionSuccess ? 'submitted' : ''}`}
              onClick={openModal}
              onMouseEnter={() => submissionSuccess && setSubmissionSuccess(false)}>
        {submissionSuccess ? "👍" : (isSubmitting ? "Submitting..." : "🐞")}
      </button>
      {isModalOpen && (
        <div className="bug-report-modal">
          <button className="closeButton" onClick={closeModal}>X</button>
          <h2>Report an issue</h2>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Describe the problem..."
          />
          <div>
            <input
              type="checkbox"
              id="screenshotCheckbox"
              checked={includeScreenshot}
              onChange={(e) => setIncludeScreenshot(e.target.checked)}
            />
            <label htmlFor="screenshotCheckbox">Include Screenshot</label>
          </div>
          <div className="buttonContainer">
            <button className="submit-button" type="submit" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      )}
    </>
  );
}

export default BugReport;