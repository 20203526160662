import React, { useState } from 'react';
import './Settings.css';
import { useUser } from "../../contexts/UserContext";
import { useHideout } from "../../contexts/HideoutContext";
import FactionSelection from "../FactionSelection/FactionSelection";
import ResetQuest from '../ResetQuest/ResetQuest';
import ConfirmationPortal from "../ConfirmationPortal/ConfirmationPortal";

function Settings({ faction, setFaction }) {
  const [confirmationType, setConfirmationType] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const { user, fetchUserInfo } = useUser();
  const { fetchUserHideout } = useHideout();
  const [showResetQuest, setShowResetQuest] = useState(false); // New state for reset quest confirmation

  const handleResetQuestClick = () => {
    setShowResetQuest(true); // Set true to display the ResetQuest component
    setMessage(""); // Clear any previous messages
  };

  // For resetting the hideout
  const handleResetHideoutClick = () => {
    setConfirmationType('resetHideout');
    setMessage("");
  };

  // For resetting the account
  const handleResetClick = () => {
    setConfirmationType('resetAccount');
    setMessage("");
  };

  // For deleting the account
  const handleDeleteClick = () => {
    setConfirmationType('deleteAccount');
    setMessage("");
  };


  const handleConfirmReset = async () => {
    try {
      // Assuming reset logic is same, call API to reset user
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset/userInfo/${user.userId}`, {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        handleResetHideout(); // Call reset hideout function
        setMessage("Account reset successfully.");
        setIsError(false);
        // Fetch updated user info after reset
        fetchUserInfo(user.userId);
        setTimeout(() => setMessage(""), 2000);
      } else {
        setMessage(`Error resetting account. Status: ${response.status}`);
        setIsError(true);
      }
    } catch (error) {
      setMessage(`Error resetting account: ${error.message}`);
      setIsError(true);
    }
    handleCloseConfirmation();
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/userInfo/delete/${user.UserId}`, {
        method: 'GET',
        credentials: 'include'
      });

      if (response.ok) {
        setMessage("Account successfully deleted.");
        setIsError(false);
        setTimeout(() => {
          window.location.href = '/'; // Redirect to the main page
        }, 2000);
      } else {
        setMessage(`Error deleting account. Status: ${response.status}`);
        setIsError(true);
      }
    } catch (error) {
      setMessage(`Error deleting account: ${error.message}`);
      setIsError(true);
    }
    setShowDeleteConfirmation(false);
  };

  const handleCloseConfirmation = () => {
    setConfirmationType('');
    setMessage("");
  };

  const handleFactionChange = async (newFaction) => {
    try {
      // Call API to update user faction
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update/userInfo/faction/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ USEC: newFaction }),
        credentials: 'include'
      });

      if (response.ok) {
        setMessage("Faction updated successfully.");
        setFaction(newFaction);
        setIsError(false);
        // Fetch updated user info after faction change
        fetchUserInfo(user.userId);
        setTimeout(() => setMessage(""), 2000);
      } else {
        throw new Error(`Status: ${response.status}`);
      }
    } catch (error) {
      setMessage(`Error updating faction: ${error.message}`);
      setIsError(true);
    }
  };

  const handleResetHideout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/userHideout/reset`, {
        credentials: 'include'
      });

      if (response.ok) {
        setMessage("Hideout reset successfully.");
      } else {
        setMessage(`Error resetting hideout. Status: ${response.status}`);
        setIsError(true);
      }
    } catch (error) {
      setMessage(`Error resetting hideout: ${error.message}`);
      setIsError(true);
    }
    handleCloseConfirmation();
    fetchUserHideout();
  }

  return (
    <>
      {confirmationType && (
        <ConfirmationPortal>
          <div className="confirmationOverlay">
            <div className="confirmationPopup">
              <div className="settingsPopupHeader">
                <h2>Confirm {confirmationType === 'resetHideout' ? 'Reset Hideout' : confirmationType === 'resetAccount' ? 'Reset' : 'Delete'}</h2>
              </div>
              <div className="confirmationPopupContent">
                <p>
                  {confirmationType === 'resetHideout' && "This will reset your hideout. Are you sure you want to reset your hideout?"}
                  {confirmationType === 'resetAccount' && "This will reset your level and completed quests. Are you sure you want to reset your account?"}
                  {confirmationType === 'deleteAccount' && "This will permanently delete your account and associated data. Are you sure you want to delete your account?"}
                </p>
                <div className="buttonContainer">
                  <button className={confirmationType === 'deleteAccount' ? "deleteButton" : "resetButton"} onClick={confirmationType === 'resetHideout' ? handleResetHideout : confirmationType === 'resetAccount' ? handleConfirmReset : handleConfirmDelete}>Confirm</button>
                  <button className="cancelButton" onClick={() => setConfirmationType('')}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </ConfirmationPortal>
      )}
      {showDeleteConfirmation && (
        <ConfirmationPortal>
          <div className="confirmationOverlay">
            <div className="confirmationPopup">
              <div className="settingsPopupHeader">
                <h2>Confirm Delete</h2>
              </div>
              <div className="confirmationPopupContent">
                <p>This will permanently delete your account and associated data. Are you sure you want to delete your account?</p>
                <div className="buttonContainer">
                  <button className="deleteButton" onClick={handleConfirmDelete}>Delete</button>
                  <button className="cancelButton" onClick={handleCloseConfirmation}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </ConfirmationPortal>
      )}
      {showResetQuest && (
        <ConfirmationPortal>
          <div className="confirmationOverlay">
            <ResetQuest onCloseResetQuest={handleCloseConfirmation} />
          </div>
        </ConfirmationPortal>
      )}
      <div className="confirmationPopupContent">
        <p className="settingsLabel">Faction</p>
        <FactionSelection
          currentFaction={faction}
          onSelectionChange={handleFactionChange}
          className="factionChangeStyle"
        />
        <button className="resetButton" onClick={handleResetQuestClick}>Reset Quests</button>
        <button className="resetButton" onClick={handleResetHideoutClick}>Reset Hideout</button>
        <button className="resetButton" onClick={handleResetClick}>Reset Account</button>
        <button className="deleteButton" onClick={handleDeleteClick}>Delete Account</button>
        {message && (
          <div className={isError ? "errorMessage" : "successMessage"}>
            {message}
          </div>
        )}
      </div>
    </>
  );
}

export default Settings;
