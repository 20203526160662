import React from 'react';
import './ItemsModal.css';
import NeededItems from "../NeededItems/NeededItems";


function ItemsModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="itemsModal">
      <div className="itemsModalHeader">
        <span>Quest Items</span>
      </div>
      <div className="itemsModalContent">
        <NeededItems className="neededItems" />
      </div>
    </div>
  );
}

export default ItemsModal;



