import React, {useCallback, useEffect, useRef} from 'react';
import './CustomsMap.css';
import customsMapImage from '../../static/images/maps/Customs.png'
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";


const CustomsMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();

  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${customsMapImage})`,
    backgroundSize: "cover",
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
    };


  return (
    <div
      className="customsMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width={3820} // Must match dimensions from useEffect above
        height={1986} // Must match dimensions from useEffect above
        viewBox="0 0 3820 1986"
      >
      {/* region paths */}
      {/*  Regex for find and replace after copying paths from SVG file */}
      {/* Copy expression below, delete before running find and replace, paste expression back into comment when done */}
      {/* (style="[^"]*"|inkscape:label="[^"]*"|<desc[^>]*>[\s\S]*?<\/desc>) */}

<path
       id="path2"
       
       d="m 1683.2669,968.92437 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.36063 29.360366,29.360366 0 0 0 29.3606,-29.36063 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.44843 23.448883,23.448883 0 0 1 -23.4484,-23.44843 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path3"
       
       d="m 591.42569,1327.8167 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path4"
       
       d="m 2884.8699,875.77139 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path5"
       
       d="m 2542.5863,1644.1041 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path6"
       
       d="m 101.83015,1531.4538 a 29.360366,29.360366 0 0 0 -29.359097,29.3606 29.360366,29.360366 0 0 0 29.359097,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.448397,-23.4484 23.448883,23.448883 0 0 1 23.448397,-23.4499 z">
      
    </path>
    <path
       id="path7"
       
       d="m 2058.7677,890.93585 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path8"
       
       d="m 389.61856,41.004815 v 42.947767 h 73.52476 V 41.004815 Z m 4.41156,4.635282 h 64.70164 v 33.675335 h -64.70164 z"
       >
      
    </path>
    <path
       id="path9"
       
       d="m 484.57697,391.23166 v 42.94777 h 74.60794 v -42.94777 z m 4.47655,4.63528 h 65.65483 v 33.67534 h -65.65483 z"
       >
      
    </path>
    <path
       id="path10"
       
       d="m 2034.9269,1317.1414 a 136.48015,136.48015 0 0 0 -136.481,136.4811 136.48015,136.48015 0 0 0 136.481,136.4792 136.48015,136.48015 0 0 0 136.4793,-136.4792 136.48015,136.48015 0 0 0 -136.4793,-136.4811 z m 0,13.1967 a 123.28278,123.28278 0 0 1 123.2826,123.2844 123.28278,123.28278 0 0 1 -123.2826,123.2825 123.28278,123.28278 0 0 1 -123.2843,-123.2825 123.28278,123.28278 0 0 1 123.2843,-123.2844 z"
       >
      
    </path>
    <path
       id="path11"
       
       d="m 1218.5861,81.248138 v 42.947772 h 74.2468 V 81.248138 Z m 4.4549,4.63529 h 65.3371 v 33.675332 h -65.3371 z"
       >
      
    </path>
    <path
       id="path12"
       
       d="m 1928.4675,1355.1544 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path13"
       
       d="m 2881.2731,828.20082 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path14"
       
       d="m 1420.9171,1341.8785 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path15"
       
       d="m 2667.8365,1127.4206 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path16"
       
       d="m 787.55409,391.25731 v 42.40619 h 74.24687 v -42.40619 z m 4.45489,4.57684 h 65.3371 v 33.25068 h -65.3371 z"
       >
      
    </path>
    <path
       id="path17"
       
       d="m 612.10465,1700.3295 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path18"
       
       d="m 3128.4103,1454.2135 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path19"
       
       d="m 612.13356,211.12018 h 42.58671 v -74.24687 h -42.58671 z m 4.59632,-4.45489 v -65.3371 h 33.39223 v 65.3371 z"
       >
      
    </path>
    <path
       id="path20"
       
       d="m 3078.5842,1200.9308 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path21"
       
       d="m 1402.7491,571.85438 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path22"
       
       d="m 109.87453,156.21461 a 29.360366,29.360366 0 0 0 -29.359104,29.3606 29.360366,29.360366 0 0 0 29.359104,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.448404,-23.4484 23.448883,23.448883 0 0 1 23.448404,-23.4499 z">
      
    </path>
    <path
       id="path23"
       
       d="m 996.30038,926.22048 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.36062,-29.3606 29.360366,29.360366 0 0 0 -29.36062,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.44982,23.4499 23.448883,23.448883 0 0 1 -23.44982,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path24"
       
       d="m 839.03128,261.40109 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.36062,-29.3606 29.360366,29.360366 0 0 0 -29.36062,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.44982,23.4499 23.448883,23.448883 0 0 1 -23.44982,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path25"
       
       d="m 3133.7304,975.23939 a 29.360366,29.360366 0 0 0 -29.3591,29.36061 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.36061 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.44991 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.44991 z">
      
    </path>
    <path
       id="path26"
       
       d="m 3388.0162,1031.4069 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path27"
       
       d="m 1372.3675,934.39024 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
    <path
       id="path28"
       
       d="M 787.46382,41.030468 V 83.97824 h 74.24687 V 41.030468 Z m 4.45489,4.635292 h 65.3371 v 33.67533 h -65.3371 z"
       >
      
    </path>
    <path
       id="path29"
       
       d="m 1418.5962,304.74153 v 41.73507 h 73.8638 v -41.73507 z m 4.4319,4.50441 h 65.0001 v 32.72445 h -65.0001 z"
       >
      
    </path>
    <path
       id="path30"
       
       d="m 2048.6415,213.45487 a 192.7555,192.7555 0 0 0 -192.748,192.75719 192.7555,192.7555 0 0 0 192.748,192.75535 192.7555,192.7555 0 0 0 192.7571,-192.75535 192.7555,192.7555 0 0 0 -192.7571,-192.75719 z m 0,17.43585 a 175.32025,175.32025 0 0 1 175.325,175.32134 175.32025,175.32025 0 0 1 -175.3214,175.31951 175.32025,175.32025 0 0 1 -175.3194,-175.31951 175.32025,175.32025 0 0 1 175.3194,-175.32134 z">
      
    </path>
    <path
       id="path33"
       
       
       d="m 2810.4808,725.0053 a 136.48015,136.48015 0 0 0 -136.481,136.4811 136.48015,136.48015 0 0 0 136.481,136.4792 136.48015,136.48015 0 0 0 136.4793,-136.4792 136.48015,136.48015 0 0 0 -136.4793,-136.4811 z m 0,13.1967 A 123.28278,123.28278 0 0 1 2933.7634,861.4864 123.28278,123.28278 0 0 1 2810.4808,984.7689 123.28278,123.28278 0 0 1 2687.1965,861.4864 123.28278,123.28278 0 0 1 2810.4808,738.202 Z M 2048.6415,213.45487 a 192.7555,192.7555 0 0 0 -192.748,192.75719 192.7555,192.7555 0 0 0 192.748,192.75535 192.7555,192.7555 0 0 0 192.7571,-192.75535 192.7555,192.7555 0 0 0 -192.7571,-192.75719 z m 0,17.43585 a 175.32025,175.32025 0 0 1 175.325,175.32134 175.32025,175.32025 0 0 1 -175.3214,175.31951 175.32025,175.32025 0 0 1 -175.3194,-175.31951 175.32025,175.32025 0 0 1 175.3194,-175.32134 z m -13.7146,1086.25068 a 136.48015,136.48015 0 0 0 -136.481,136.4811 136.48015,136.48015 0 0 0 136.481,136.4792 136.48015,136.48015 0 0 0 136.4793,-136.4792 136.48015,136.48015 0 0 0 -136.4793,-136.4811 z m 0,13.1967 a 123.28278,123.28278 0 0 1 123.2826,123.2844 123.28278,123.28278 0 0 1 -123.2826,123.2825 123.28278,123.28278 0 0 1 -123.2843,-123.2825 123.28278,123.28278 0 0 1 123.2843,-123.2844 z">
      
    </path>
    <path
       id="path34"
       
       d="m 1983.3413,161.80146 a 29.360366,29.360366 0 0 0 -29.3591,29.3606 29.360366,29.360366 0 0 0 29.3591,29.3606 29.360366,29.360366 0 0 0 29.3606,-29.3606 29.360366,29.360366 0 0 0 -29.3606,-29.3606 z m 0,5.9107 a 23.448883,23.448883 0 0 1 23.4498,23.4499 23.448883,23.448883 0 0 1 -23.4498,23.4484 23.448883,23.448883 0 0 1 -23.4484,-23.4484 23.448883,23.448883 0 0 1 23.4484,-23.4499 z">
      
    </path>
      {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default CustomsMap
