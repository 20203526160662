import React, {useCallback, useEffect, useRef} from 'react';
import './FactoryMap.css';
import factoryMapImage from "../../static/images/maps/Factory.png";
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";
import groundZeroMapImage from "../../static/images/maps/GroundZero.png";


const FactoryMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();

  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${factoryMapImage})`,
    backgroundSize: "cover",
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };


  return (
    <div
      className="factoryMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width={5093.3335}
        height={2865.3333}
        viewBox="0 0 5093.3335 2865.3333"
      >
      {/* region paths */}
      <path
        id="path100"
        d="m 1959.54,974.25029 a 57.138776,57.138776 0 0 0 -57.1361,57.13911 57.138776,57.138776 0 0 0 57.1361,57.139 57.138776,57.138776 0 0 0 57.1392,-57.139 57.138776,57.138776 0 0 0 -57.1392,-57.13911 z m 0,11.50292 a 45.634325,45.634325 0 0 1 45.6363,45.63619 45.634325,45.634325 0 0 1 -45.6363,45.6332 45.634325,45.634325 0 0 1 -45.6332,-45.6332 45.634325,45.634325 0 0 1 45.6332,-45.63619 z"
      >
        <desc id="desc1">{"Objective IDs: \r\n7"}</desc>
      </path>
      <path
        id="path101"
        d="m 591.21976,1101.494 a 99.254454,99.254454 0 0 0 -99.2498,99.2551 99.254454,99.254454 0 0 0 99.2498,99.2548 99.254454,99.254454 0 0 0 99.25526,-99.2548 99.254454,99.254454 0 0 0 -99.25526,-99.2551 z m 0,19.9813 a 79.270337,79.270337 0 0 1 79.27376,79.2738 79.270337,79.270337 0 0 1 -79.27376,79.2683 79.270337,79.270337 0 0 1 -79.2683,-79.2683 79.270337,79.270337 0 0 1 79.2683,-79.2738 z"
      >
        <desc id="desc2">{"Objective IDs: \r\n26"}</desc>
      </path>
      <path
        id="path102"
        d="m 2253.4551,1895.4232 a 57.138776,57.138776 0 0 0 -57.1361,57.1391 57.138776,57.138776 0 0 0 57.1361,57.139 57.138776,57.138776 0 0 0 57.1392,-57.139 57.138776,57.138776 0 0 0 -57.1392,-57.1391 z m 0,11.5029 a 45.634325,45.634325 0 0 1 45.6363,45.6362 45.634325,45.634325 0 0 1 -45.6363,45.6332 45.634325,45.634325 0 0 1 -45.6332,-45.6332 45.634325,45.634325 0 0 1 45.6332,-45.6362 z"
      >
        <desc id="desc3">{"Objective IDs: \r\n28"}</desc>
      </path>
      <path
        id="path103"
        d="m 589.42759,1196.4788 a 99.254454,99.254454 0 0 0 -99.2498,99.2551 99.254454,99.254454 0 0 0 99.2498,99.2548 99.254454,99.254454 0 0 0 99.25526,-99.2548 99.254454,99.254454 0 0 0 -99.25526,-99.2551 z m 0,19.9813 a 79.270337,79.270337 0 0 1 79.27376,79.2738 79.270337,79.270337 0 0 1 -79.27376,79.2683 79.270337,79.270337 0 0 1 -79.2683,-79.2683 79.270337,79.270337 0 0 1 79.2683,-79.2738 z"
      >
        <desc id="desc4">{"Objective IDs: \r\n117\r\n148"}</desc>
      </path>
      <path
        id="path104"
        d="m 3478.3977,1834.4891 a 108.21546,108.21546 0 0 0 -108.2104,108.2162 108.21546,108.21546 0 0 0 108.2104,108.2158 108.21546,108.21546 0 0 0 108.2163,-108.2158 108.21546,108.21546 0 0 0 -108.2163,-108.2162 z m 0,21.7853 a 86.427111,86.427111 0 0 1 86.4308,86.4309 86.427111,86.427111 0 0 1 -86.4308,86.4249 86.427111,86.427111 0 0 1 -86.4249,-86.4249 86.427111,86.427111 0 0 1 86.4249,-86.4309 z M 4768.7568,467.06757 a 99.254454,99.254454 0 0 0 -99.2498,99.2551 99.254454,99.254454 0 0 0 99.2498,99.2548 99.254454,99.254454 0 0 0 99.2553,-99.2548 99.254454,99.254454 0 0 0 -99.2553,-99.2551 z m 0,19.9813 a 79.270337,79.270337 0 0 1 79.2738,79.2738 79.270337,79.270337 0 0 1 -79.2738,79.2683 79.270337,79.270337 0 0 1 -79.2683,-79.2683 79.270337,79.270337 0 0 1 79.2683,-79.2738 z M 1738.2054,2529.8498 a 99.254454,99.254454 0 0 0 -99.2498,99.2551 99.254454,99.254454 0 0 0 99.2498,99.2548 99.254454,99.254454 0 0 0 99.2553,-99.2548 99.254454,99.254454 0 0 0 -99.2553,-99.2551 z m 0,19.9813 a 79.270337,79.270337 0 0 1 79.2738,79.2738 79.270337,79.270337 0 0 1 -79.2738,79.2683 79.270337,79.270337 0 0 1 -79.2683,-79.2683 79.270337,79.270337 0 0 1 79.2683,-79.2738 z M 1589.4558,660.62141 a 99.254454,99.254454 0 0 0 -99.2498,99.2551 99.254454,99.254454 0 0 0 99.2498,99.2548 99.254454,99.254454 0 0 0 99.2552,-99.2548 99.254454,99.254454 0 0 0 -99.2552,-99.2551 z m 0,19.9813 a 79.270337,79.270337 0 0 1 79.2737,79.2738 79.270337,79.270337 0 0 1 -79.2737,79.2683 79.270337,79.270337 0 0 1 -79.2683,-79.2683 79.270337,79.270337 0 0 1 79.2683,-79.2738 z"
      >
        <desc id="desc8">{"Objective IDs: \r\n370\r\n371\r\n372\r\n373"}</desc>
      </path>
      <path
        id="path105"
        d="m 3350.2602,692.88036 a 57.138776,57.138776 0 0 0 -57.1361,57.13911 57.138776,57.138776 0 0 0 57.1361,57.139 57.138776,57.138776 0 0 0 57.1392,-57.139 57.138776,57.138776 0 0 0 -57.1392,-57.13911 z m 0,11.50292 a 45.634325,45.634325 0 0 1 45.6363,45.63619 45.634325,45.634325 0 0 1 -45.6363,45.6332 45.634325,45.634325 0 0 1 -45.6332,-45.6332 45.634325,45.634325 0 0 1 45.6332,-45.63619 z"
      >
        <desc id="desc9">{"Objective IDs: \r\n398"}</desc>
      </path>
      <path
        id="path106"
        d="m 2708.6651,567.4288 a 57.138776,57.138776 0 0 0 -57.1361,57.13911 57.138776,57.138776 0 0 0 57.1361,57.139 57.138776,57.138776 0 0 0 57.1392,-57.139 57.138776,57.138776 0 0 0 -57.1392,-57.13911 z m 0,11.50292 a 45.634325,45.634325 0 0 1 45.6363,45.63619 45.634325,45.634325 0 0 1 -45.6363,45.6332 45.634325,45.634325 0 0 1 -45.6332,-45.6332 45.634325,45.634325 0 0 1 45.6332,-45.63619 z"
      >
        <desc id="desc10">{"Objective IDs: \r\n399"}</desc>
      </path>
      <path
        id="path107"
        d="M 1863.8516 1080.6758 L 1863.8516 1557.3906 L 2091.457 1557.3906 L 2091.457 1080.6758 L 1863.8516 1080.6758 z M 1878.1895 1098.5977 L 2077.1191 1098.5977 L 2077.1191 1539.4707 L 1878.1895 1539.4707 L 1878.1895 1098.5977 z "
      >
        <desc id="desc11">{"Objective IDs:\r\n481"}</desc>
      </path>
      {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default FactoryMap