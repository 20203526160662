import React, { useEffect, useState, useRef } from 'react';
import './NeededItems.css';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import { Tooltip } from 'react-tooltip';
import TooltipPortal from '../TooltipPortal/TooltipPortal';
import { useQuests } from "../../contexts/QuestContext";


function NeededItems({ className }) {
  const { itemInfo, questDetails, fetchAllItemInfo } = useQuests();
  const gridRef = useRef();
  const [sortedItems, setSortedItems] = useState([]);
  const [showLevelsAhead, setShowLevelsAhead] = useState(false);
  const [levelsAhead, setLevelsAhead] = useState(0);
  let masonryInstance = null;

  useEffect(() => {
    const fetchItems = async () => {
      try {
        await fetchAllItemInfo(showLevelsAhead, levelsAhead);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    };

    // Determine if the fetch should proceed
    const shouldFetch = showLevelsAhead ? (levelsAhead !== '' && !isNaN(levelsAhead) && levelsAhead >= 0) : true;

    if (shouldFetch) {
      fetchItems();
    }
  }, [showLevelsAhead, levelsAhead]); // Re-fetch when these values change



  useEffect(() => {
    // Initialize Masonry after images have loaded
    if (gridRef.current) {
      imagesLoaded(gridRef.current, function() {
        if (!masonryInstance) {
          // Use a sizer div for dynamic column widths
          const sizer = gridRef.current.querySelector('.grid-sizer');
          masonryInstance = new Masonry(gridRef.current, {
            itemSelector: '.itemItem',
            columnWidth: sizer,
            fitWidth: true,
            gutter: 0
          });
        }
        masonryInstance.layout();
      });
    }

    // Clean up Masonry instance on component unmount
    return () => {
      if (masonryInstance) {
        masonryInstance.destroy();
      }
    };
  }, [sortedItems]);


  useEffect(() => {
    const loadImageSizes = async () => {
      const itemsWithSize = await Promise.all(
        Object.entries(itemInfo).map(async ([itemName, itemData]) => {
          return new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
              resolve({ itemName, itemData, width: img.width, height: img.height });
            };
            img.src = `${process.env.REACT_APP_API_URL}/static/icons/items/${itemData.itemInfo.ImagePath}`;
          });
        })
      );

      // Sort items based on size (e.g., by width, height, or area)
      const sorted = itemsWithSize.sort((a, b) => a.width * a.height - b.width * b.height);
      setSortedItems(sorted);
    };

    loadImageSizes();
  }, [itemInfo]); // Depend on itemInfo

  // Render items
  const renderItems = () => {
    return sortedItems.map(({ itemName, itemData, width, height }) => {
      const itemDetails = itemData.itemInfo;

      // Convert the array of quest names to a Set to remove duplicates, then back to an array for mapping
      const uniqueQuestNames = [...new Set(itemData.quests.map(questId => questId))];

      const questNamesElements = uniqueQuestNames.map(questName => (
        <p key={questName} style={{ color: '#9F9F9FFF' }}>
          {questName || 'Unknown Quest'}
        </p>
      ));

      const tooltipId = `tooltip-${itemDetails.ItemId}`;

      return (
        <div key={itemDetails.ItemId} className="itemItem">
          <img
            data-tooltip-id={tooltipId}
            src={`${process.env.REACT_APP_API_URL}/static/icons/items/${itemDetails.ImagePath}`}
            alt={itemName}
          />
          <span className="itemCount">{itemData.combinedCount}</span>
          <TooltipPortal>
            <Tooltip
              id={tooltipId}
              className="custom-tooltip"
              style={{
                backgroundColor: 'black',
                borderRadius: '5px',
                '--rt-opacity': 1,
                fontSize: '16px',
              }}
            >
              <div>
                <p style={{ fontWeight: 'bold' }}>{itemName}</p>
                {questNamesElements} {/* Render quest names as separate elements */}
              </div>
            </Tooltip>
          </TooltipPortal>
        </div>
      );
    });
  };


  return (
    <div className="controlsAndItemsContainer">
      <div className="inputControls">
        <div className="checkboxControl">
          <input
            type="checkbox"
            checked={showLevelsAhead}
            onChange={(e) => setShowLevelsAhead(e.target.checked)}
          />
          <label>
            Show all needed quest items
          </label>
        </div>
        {showLevelsAhead && (
          <div className="numberInputControl">
            <input
              className="levelsAheadInput"
              type="number"
              value={levelsAhead}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only digits or empty input
                if (value === '' || /^\d+$/.test(value)) {
                  setLevelsAhead(value === '' ? '' : parseInt(value, 10));
                }
              }}
              placeholder=""
            />
            <label>
              levels ahead
            </label>
          </div>
        )}
      </div>
      <div className="itemsScrollContainer">
      <div ref={gridRef} className="itemsList">
        <div className="grid-sizer"></div> {/* This element is used for Masonry's columnWidth */}
        {renderItems()}
      </div>
    </div>
  </div>
  );
}

export default NeededItems;
