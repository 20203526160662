import React from 'react';
import './FiltersModal.css';


function FiltersModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modalHeader">
        <span>Filters</span>
      </div>
      <div className="modalContent">
        {/* Filters modal specific content here */}
      </div>
    </div>
  );
}

export default FiltersModal;



