import React, { useState, useEffect } from 'react';
import "./LevelInput.css";
import settings from "../../static/images/icons/settings.svg";
import plusIcon from "../../static/images/icons/plus.png";
import minusIcon from "../../static/images/icons/minus.png";
import EODicon from "../../static/images/icons/EODicon.png";
import { useUser } from "../../contexts/UserContext";

function LevelInput({ onSettingsClick }) {
  const { user, setUser } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [editLevel, setEditLevel] = useState(user.Level);

  useEffect(() => {
    setEditLevel(user.Level); // Update editLevel when user.Level changes
  }, [user.Level]);

  const changeLevel = async (newLevel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update/userInfo/level`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ Level: newLevel }),
      });

      if (response.ok) {
        console.log("User level updated successfully.");
        setUser({ ...user, Level: newLevel.toString() }); // Update level in context

      } else {
        console.error("Error updating user level. Status:", response.status);
      }
    } catch (error) {
      console.error('Error updating user level:', error);
    }
  };


  const increaseLevel = () => {
    if (Number(user.Level) < 100) {
      changeLevel(Number(user.Level) + 1);
    }
  };

  const decreaseLevel = () => {
    if (Number(user.Level) > 1) {
      changeLevel(Number(user.Level) - 1);
    }
  };

  const handleLevelClick = () => {
    setIsEditing(true);
  };

  const handleLevelChange = (newValue) => {
    if (newValue === '') {
      setEditLevel(newValue); // Allow the input to be empty
    } else {
      const numValue = parseInt(newValue, 10);
      if (!isNaN(numValue) && numValue >= 1 && numValue <= 100) {
        setEditLevel(numValue);
      }
    }
  };


  const handleSave = async () => {
    const newLevel = parseInt(editLevel, 10); // Define newLevel using editLevel
    if (!isNaN(newLevel) && newLevel !== Number(user.Level)) {
      await changeLevel(newLevel); // Update level with newLevel
      }
      setIsEditing(false);
    };


  return (
    <div className="levelInputContainer">
      <div className="userDisplay">
        <img src={EODicon} alt="EOD Icon" className="eodIcon" />
        <span className="userName">{user.Login}</span> {/* Use Login from user context */}
      </div>
      <div className="centeredContent">
        <div className="levelDisplayWrapper">
          <h2>Level: {isEditing ? (
            <input
              type="number"
              min="1"
              max="100"
              value={editLevel}
              onChange={(e) => handleLevelChange(e.target.value)}
              onBlur={handleSave}
            />
          ) : (
            <span onClick={handleLevelClick}>{user.Level}</span>
          )}</h2>

          {isEditing ? (
            <button className="saveButton" onClick={handleSave}>Save</button>
          ) : (
            <div className="buttonGroup">
              <button onClick={increaseLevel}>
                <img src={plusIcon} alt="Increase Level" />
              </button>
              <button onClick={decreaseLevel}>
                <img src={minusIcon} alt="Decrease Level" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export default LevelInput;