import React, { useState } from 'react';
import './QuestListHeader.css';

function QuestListHeader({ onSortChange, sortCriteria, onLocationFilterChange, locationFilter }) {
  const getSortIndicator = (field) => {
    if (sortCriteria.field === field) {
      return sortCriteria.order === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  const [showLocationDropdown, setShowLocationDropdown] = useState(false);
  const availableMaps = ['Customs', 'Factory', 'Interchange', 'Labs', 'Lighthouse', 'Reserve', 'Shoreline', 'Streets', 'Woods', 'Ground Zero']; // Replace with your actual map names
  const locationHeaderStyle = locationFilter ? { color: '#ca8a00' } : { color: 'white' }; // Changes color when a filter is active

  const handleLocationClick = () => {
    setShowLocationDropdown(!showLocationDropdown);
  };

  const handleLocationSelect = (location) => {
    onLocationFilterChange(location); // Pass an empty string to remove the filter
    setShowLocationDropdown(false);
  };


  return (
    <div className="questListHeader">
      <div className="trader sortableHeader" onClick={() => onSortChange('trader')}>
        Trader <span className="sortIndicator">{getSortIndicator('trader')}</span>
      </div>
      <div className="task sortableHeader" onClick={() => onSortChange('task')}>
        Task <span className="sortIndicator">{getSortIndicator('task')}</span>
      </div>
      <div className="location sortableHeader" onClick={handleLocationClick}>
        <span style={locationHeaderStyle}>Location</span> <span className="sortIndicator">{getSortIndicator('location')}</span>
        {showLocationDropdown && (
          <div className="locationDropdown">
            <div
              key="all"
              onClick={() => handleLocationSelect('')}
              className={locationFilter === '' ? 'selectedLocation' : ''}
            >
              All
            </div>
            {availableMaps.map(map => (
              <div
                key={map}
                onClick={() => handleLocationSelect(map)}
                className={locationFilter === map ? 'selectedLocation' : ''}
              >
                {map}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="description">Description</div>
      <div className="complete">Complete</div>
    </div>
  );
}

export default QuestListHeader;
