import React from 'react';
import './ObjectiveScreenshotModal.css';
import { useModal } from '../../contexts/ModalContext'; // Adjust the import path as necessary
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  VStack,
} from '@chakra-ui/react';

const handleImageClick = async (event) => {
  if (event.currentTarget.requestFullscreen) {
    await event.currentTarget.requestFullscreen();
  } else if (event.currentTarget.mozRequestFullScreen) { /* Firefox */
    await event.currentTarget.mozRequestFullScreen();
  } else if (event.currentTarget.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    await event.currentTarget.webkitRequestFullscreen();
  } else if (event.currentTarget.msRequestFullscreen) { /* IE/Edge */
    await event.currentTarget.msRequestFullscreen();
  }
};


const ObjectiveScreenshotModal = () => {
  const { isModalOpen, modalContent, closeModal } = useModal();

  // Initialize default states
  let images = [];
  let description = '';

  // Determine the type of modalContent and extract information accordingly
  if (typeof modalContent === 'string') {
    images = [modalContent]; // Treat as a single image URL
  } else if (Array.isArray(modalContent)) {
    images = modalContent; // Treat as multiple image URLs
  } else if (typeof modalContent === 'object' && modalContent !== null) {
    images = modalContent.images || [];
    description = modalContent.description || '';
  }

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} isCentered size="6xl">
      <ModalOverlay />
      <ModalContent className="customModalContent" bg="black" color="white">
        <ModalHeader textAlign="center">{description || "Objective Screenshot"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="customModalBody">
          <VStack spacing={4}>
            {images.map((imgSrc, index) => (
              <Image
                key={index}
                src={imgSrc}
                maxW="100%"
                maxHeight="600px"
                objectFit="contain"
                onClick={handleImageClick}
                cursor="pointer"
              />
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ObjectiveScreenshotModal;
