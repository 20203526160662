import React from 'react';
import './MapSelection.css';

function MapSelection({ isOpen, onClose, onMapSelect }) {
  if (!isOpen) {
    return null;
  }

  const maps = ['Customs', 'Factory', 'Interchange', 'Labs', 'Lighthouse', 'Reserve', 'Shoreline', 'Streets', 'Woods', 'Ground Zero'];

  const handleMapClick = (map) => {
    onMapSelect(map);
    onClose();
  };

  return (
    <div className="mapSelectionContainer">
      <div className="popup">
        <div className="mapPopupHeader">
          <h2>Select a map</h2>
          <button className="closeButton" onClick={onClose}>x</button>
        </div>
        <div className="popupContent">
          {maps.map(map => (
            <button
              key={map}
              onClick={() => handleMapClick(map)}
            >
              {map}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MapSelection;
