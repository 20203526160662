import React, { useState, useCallback, useRef, useEffect } from "react";
import "./MapDisplay.css";

import CustomsMap from "../CustomsMap/CustomsMap";
import FactoryMap from "../FactoryMap/FactoryMap";
import InterchangeMap from "../InterchangeMap/InterchangeMap";
import LabsMap from "../LabsMap/LabsMap";
import LighthouseMap from "../LighthouseMap/LighthouseMap";
import ReserveMap from "../ReserveMap/ReserveMap";
import ShorelineMap from "../ShorelineMap/ShorelineMap";
import StreetsMap from "../StreetsMap/StreetsMap";
import WoodsMap from "../WoodsMap/WoodsMap";
import GroundZeroMap from "../GroundZeroMap/GroundZeroMap";

import FiltersModal from "../FiltersModal/FiltersModal";
import QuestsModal from "../QuestsModal/QuestsModal";
import ItemsModal from "../ItemsModal/ItemsModal";
import KeysModal from "../KeysModal/KeysModal";
import ObjectiveScreenshotModal from "../ObjectiveScreenshotModal/ObjectiveScreenshotModal";

function MapDisplay({ selectedMap, onClose }) {
  const mapImages = {};
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialPinchDistance, setInitialPinchDistance] = useState(null);
  const [initialZoom, setInitialZoom] = useState(1);
  const mapViewRef = useRef(null);
  const overlayRef = useRef(null);
  const [modalOpen, setModalOpen] = useState({
    filters: false,
    quests: false,
    items: false,
    keys: false,
  });

  const toggleModal = (modalName) => {
    setModalOpen((prev) => ({ ...prev, [modalName]: !prev[modalName] }));
  };

  const handleWheel = useCallback((event) => {
    const scaleAmount = 1.2;
    if (event.deltaY < 0) {
      setZoomLevel((zoomLevel) => Math.min(zoomLevel * scaleAmount, 5));
    } else {
      setZoomLevel((zoomLevel) => Math.max(zoomLevel / scaleAmount, 0.5));
    }
  }, []);

  const handleMouseDown = useCallback(
    (event) => {
      const startPosition = { x: event.clientX, y: event.clientY };
      const handleMouseMove = (moveEvent) => {
        const dx = (moveEvent.clientX - startPosition.x) / zoomLevel;
        const dy = (moveEvent.clientY - startPosition.y) / zoomLevel;
        setPosition((position) => ({
          x: position.x + dx,
          y: position.y + dy,
        }));
        startPosition.x = moveEvent.clientX;
        startPosition.y = moveEvent.clientY;
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [zoomLevel]
  );

  const handleTouchStart = useCallback(
    (event) => {
      if (event.touches.length === 1) {
        const touch = event.touches[0];
        const startPosition = { x: touch.clientX, y: touch.clientY };
        const handleTouchMove = (moveEvent) => {
          const touchPoint = moveEvent.touches[0];
          const dx = (touchPoint.clientX - startPosition.x) / zoomLevel;
          const dy = (touchPoint.clientY - startPosition.y) / zoomLevel;
          setPosition((position) => ({
            x: position.x + dx,
            y: position.y + dy,
          }));
          startPosition.x = touchPoint.clientX;
          startPosition.y = touchPoint.clientY;
        };
        const handleTouchEnd = () => {
          document.removeEventListener("touchmove", handleTouchMove);
          document.removeEventListener("touchend", handleTouchEnd);
        };
        document.addEventListener("touchmove", handleTouchMove, {
          passive: false,
        });
        document.addEventListener("touchend", handleTouchEnd);
      } else if (event.touches.length === 2) {
        setInitialPinchDistance(null);
        setInitialZoom(zoomLevel);
      }
      event.preventDefault();
    },
    [zoomLevel, setPosition]
  );

  const handleTouchMove = useCallback(
    (event) => {
      if (event.touches.length === 2) {
        event.preventDefault(); // Prevent default pinch-to-zoom action of the browser
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        const dx = touch1.pageX - touch2.pageX;
        const dy = touch1.pageY - touch2.pageY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (initialPinchDistance === null) {
          setInitialPinchDistance(distance);
        } else {
          const scale = distance / initialPinchDistance;
          const zoomChange = scale * initialZoom - initialZoom;
          setZoomLevel(Math.max(0.5, Math.min(initialZoom + zoomChange, 5)));
        }
      }
    },
    [initialPinchDistance, initialZoom]
  );

  useEffect(() => {
    const mapViewElement = mapViewRef.current;
    if (mapViewElement) {
      mapViewElement.addEventListener("mousedown", handleMouseDown);
      mapViewElement.addEventListener("wheel", handleWheel);
      mapViewElement.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      mapViewElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      return () => {
        mapViewElement.removeEventListener("mousedown", handleMouseDown);
        mapViewElement.removeEventListener("wheel", handleWheel);
        mapViewElement.removeEventListener("touchstart", handleTouchStart);
        mapViewElement.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [handleMouseDown, handleWheel, handleTouchStart, handleTouchMove]);

  const renderMap = () => {
    if (selectedMap === "Customs") {
      return <CustomsMap />;
    } else if (selectedMap === "Woods") {
      return <WoodsMap />;
    } else if (selectedMap === "Factory") {
      return <FactoryMap />;
    } else if (selectedMap === "Shoreline") {
      return <ShorelineMap />;
    } else if (selectedMap === "Interchange") {
      return <InterchangeMap />;
    } else if (selectedMap === "Labs") {
      return <LabsMap />;
    } else if (selectedMap === "Lighthouse") {
      return <LighthouseMap />;
    } else if (selectedMap === "Reserve") {
      return <ReserveMap />;
    } else if (selectedMap === "Streets") {
      return <StreetsMap />;
    } else if (selectedMap === "Ground Zero") {
      return <GroundZeroMap />;
    } else {
      const selectedMapImage = mapImages[selectedMap];
      return <img src={selectedMapImage} alt={selectedMap} />;
    }
  };

  const mapStyle = {
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div className="mapOverlay" ref={overlayRef}>
      {/* Navigation Bar */}
      <div className="mapNav">
        <div className="mapNavItem" onClick={() => toggleModal("filters")}>
          <pre
            className={`mapNavIcon ${
              modalOpen.filters ? "mapNavIconActive" : ""
            }`}
          >
            🗺️
          </pre>
          <div className="mapNavLabel">Filters</div>
        </div>
        <div className="mapNavItem" onClick={() => toggleModal("quests")}>
          <div
            className={`mapNavIcon ${
              modalOpen.quests ? "mapNavIconActive" : ""
            }`}
          >
            🔍
          </div>
          <div className="mapNavLabel">Quests</div>
        </div>
        <div className="mapNavItem" onClick={() => toggleModal("items")}>
          <div
            className={`mapNavIcon ${
              modalOpen.items ? "mapNavIconActive" : ""
            }`}
          >
            🎒
          </div>
          <div className="mapNavLabel">Items</div>
        </div>
        <div className="mapNavItem" onClick={() => toggleModal("keys")}>
          <div
            className={`mapNavIcon ${modalOpen.keys ? "mapNavIconActive" : ""}`}
          >
            🔑
          </div>
          <div className="mapNavLabel">Keys</div>
        </div>
        <div className={`mapNavItem ${"mapNavLastItem"}`} onClick={onClose}>
          <div className="mapNavIcon">❌</div>
          <div className="mapNavLabel">Close</div>
        </div>
      </div>
      <div className="modalContainer" >
        <FiltersModal
          selectedMap={selectedMap}
          isOpen={modalOpen.filters}
          onClose={() => toggleModal("filters")}
        />
        <QuestsModal
          selectedMap={selectedMap}
          isOpen={modalOpen.quests}
          onClose={() => toggleModal("quests")}
        />
        <ItemsModal
          selectedMap={selectedMap}
          isOpen={modalOpen.items}
          onClose={() => toggleModal("items")}
        />
        <KeysModal
          selectedMap={selectedMap}
          isOpen={modalOpen.keys}
          onClose={() => toggleModal("keys")}
        />
        <ObjectiveScreenshotModal />
      </div>
      <div className="mapDisplayContainer">
        <div
          className="mapView"
          ref={mapViewRef}
          style={mapStyle}
          onWheel={handleWheel}
          onMouseDown={handleMouseDown}
        >
          {renderMap()}
        </div>
      </div>
    </div>
  );
}

export default MapDisplay;
