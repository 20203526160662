// Component that displays the hideout levels of each module. The user can click on a module to increase its level.
import React from 'react';
import './HideoutLevel.css';
import medStationImage from "../../static/images/hideoutIcons/Medstation.png";
import redMedStationImage from "../../static/images/hideoutIcons/RedMedstation.png";
import nutritionUnitImage from "../../static/images/hideoutIcons/NutritionUnit.png";
import redNutritionUnitImage from "../../static/images/hideoutIcons/RedNutritionUnit.png";
import waterCollectorImage from "../../static/images/hideoutIcons/WaterCollector.png";
import redWaterCollectorImage from "../../static/images/hideoutIcons/RedWaterCollector.png";
import securityImage from "../../static/images/hideoutIcons/Security.png";
import redSecurityImage from "../../static/images/hideoutIcons/RedSecurity.png";
import stashImage from "../../static/images/hideoutIcons/Stash.png";
import heatingImage from "../../static/images/hideoutIcons/Heating.png";
import redHeatingImage from "../../static/images/hideoutIcons/RedHeating.png";
import lavatoryImage from "../../static/images/hideoutIcons/Lavatory.png";
import redLavatoryImage from "../../static/images/hideoutIcons/RedLavatory.png";
import shootingRangeImage from "../../static/images/hideoutIcons/ShootingRange.png";
import redShootingRangeImage from "../../static/images/hideoutIcons/RedShootingRange.png";
import intelligenceCenterImage from "../../static/images/hideoutIcons/IntelligenceCenter.png";
import redIntelligenceCenterImage from "../../static/images/hideoutIcons/RedIntelligenceCenter.png";
import workbenchImage from "../../static/images/hideoutIcons/Workbench.png";
import redWorkbenchImage from "../../static/images/hideoutIcons/RedWorkbench.png";
import ventsImage from "../../static/images/hideoutIcons/Vents.png";
import redVentsImage from "../../static/images/hideoutIcons/RedVents.png";
import generatorImage from "../../static/images/hideoutIcons/Generator.png";
import redGeneratorImage from "../../static/images/hideoutIcons/RedGenerator.png";
import illuminationImage from "../../static/images/hideoutIcons/Illumination.png";
import redIlluminationImage from "../../static/images/hideoutIcons/RedIllumination.png";
import restSpaceImage from "../../static/images/hideoutIcons/RestSpace.png";
import redRestSpaceImage from "../../static/images/hideoutIcons/RedRestSpace.png";

import HideoutLevelIcon from "../HideoutLevelIcon/HideoutLevelIcon";
import { useHideout } from '../../contexts/HideoutContext';

function HideoutLevel() {
  const { hideout, updateHideoutLevels } = useHideout();

  const updateHideout = (module, currentLevel) => {
    let numericCurrentLevel = Number(currentLevel);
    let newLevel;

    // Special handling for the Stash module
    if (module === 'Stash') {
      newLevel = numericCurrentLevel === 4 ? 1 : numericCurrentLevel + 1;
    } else {
      newLevel = (numericCurrentLevel + 1) % 5;
    }

    updateHideoutLevels(module, newLevel);
  };


  const medStationSrc = hideout.Medstation > 0 ? medStationImage : redMedStationImage;
  const nutritionUnitSrc = hideout.NutritionUnit > 0 ? nutritionUnitImage : redNutritionUnitImage;
  const waterCollectorSrc = hideout.WaterCollector > 0 ? waterCollectorImage : redWaterCollectorImage;
  const securitySrc = hideout.Security > 0 ? securityImage : redSecurityImage;
  const stashSrc = hideout.Stash > 0 ? stashImage : stashImage;
  const heatingSrc = hideout.Heating > 0 ? heatingImage : redHeatingImage;
  const lavatorySrc = hideout.Lavatory > 0 ? lavatoryImage : redLavatoryImage;
  const shootingRangeSrc = hideout.ShootingRange > 0 ? shootingRangeImage : redShootingRangeImage;
  const intelligenceCenterSrc = hideout.IntelligenceCenter > 0 ? intelligenceCenterImage : redIntelligenceCenterImage;
  const workbenchSrc = hideout.Workbench > 0 ? workbenchImage : redWorkbenchImage;
  const ventsSrc = hideout.Vents > 0 ? ventsImage : redVentsImage;
  const generatorSrc = hideout.Generator > 0 ? generatorImage : redGeneratorImage;
  const illuminationSrc = hideout.Illumination > 0 ? illuminationImage : redIlluminationImage;
  const restSpaceSrc = hideout.RestSpace > 0 ? restSpaceImage : redRestSpaceImage;


  return (
    <div className="hideoutLevelBox">
      <div className="moduleWrap" onClick={() => updateHideout('Medstation', Number(hideout.Medstation))}>
        <p className="moduleName">Medstation</p>
        <img className="moduleImage" src={medStationSrc} alt="Medstation" />
        <HideoutLevelIcon level={Number(hideout.Medstation)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('NutritionUnit', Number(hideout.NutritionUnit))}>
        <p className="moduleName">Nutrition Unit</p>
        <img className="moduleImage" src={nutritionUnitSrc} alt="Nutrition Unit" />
        <HideoutLevelIcon level={Number(hideout.NutritionUnit)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('WaterCollector', Number(hideout.WaterCollector))}>
        <p className="moduleName">Water Collector</p>
        <img className="moduleImage" src={waterCollectorSrc} alt="Water Collector" />
        <HideoutLevelIcon level={Number(hideout.WaterCollector)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Security', Number(hideout.Security))}>
        <p className="moduleName">Security</p>
        <img className="moduleImage" src={securitySrc} alt="Security" />
        <HideoutLevelIcon level={Number(hideout.Security)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Stash', Number(hideout.Stash))}>
        <p className="moduleName">Stash</p>
        <img className="moduleImage" src={stashSrc} alt="Stash" />
        <HideoutLevelIcon level={Number(hideout.Stash)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Heating', Number(hideout.Heating))}>
        <p className="moduleName">Heating</p>
        <img className="moduleImage" src={heatingSrc} alt="Heating" />
        <HideoutLevelIcon level={Number(hideout.Heating)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Lavatory', Number(hideout.Lavatory))}>
        <p className="moduleName">Lavatory</p>
        <img className="moduleImage" src={lavatorySrc} alt="Lavatory" />
        <HideoutLevelIcon level={Number(hideout.Lavatory)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('ShootingRange', Number(hideout.ShootingRange))}>
        <p className="moduleName">Shooting Range</p>
        <img className="moduleImage" src={shootingRangeSrc} alt="Shooting Range" />
        <HideoutLevelIcon level={Number(hideout.ShootingRange)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('IntelligenceCenter', Number(hideout.IntelligenceCenter))}>
        <p className="moduleName">Intelligence Center</p>
        <img className="moduleImage" src={intelligenceCenterSrc} alt="Intelligence Center" />
        <HideoutLevelIcon level={Number(hideout.IntelligenceCenter)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Workbench', Number(hideout.Workbench))}>
        <p className="moduleName">Workbench</p>
        <img className="moduleImage" src={workbenchSrc} alt="Workbench" />
        <HideoutLevelIcon level={Number(hideout.Workbench)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Vents', Number(hideout.Vents))}>
        <p className="moduleName">Vents</p>
        <img className="moduleImage" src={ventsSrc} alt="Vents" />
        <HideoutLevelIcon level={Number(hideout.Vents)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Generator', Number(hideout.Generator))}>
        <p className="moduleName">Generator</p>
        <img className="moduleImage" src={generatorSrc} alt="Generator" />
        <HideoutLevelIcon level={Number(hideout.Generator)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('Illumination', Number(hideout.Illumination))}>
        <p className="moduleName">Illumination</p>
        <img className="moduleImage" src={illuminationSrc} alt="Illumination" />
        <HideoutLevelIcon level={Number(hideout.Illumination)} />
      </div>
      <div className="moduleWrap" onClick={() => updateHideout('RestSpace', Number(hideout.RestSpace))}>
        <p className="moduleName">Rest Space</p>
        <img className="moduleImage" src={restSpaceSrc} alt="Rest Space" />
        <HideoutLevelIcon level={Number(hideout.RestSpace)} />
      </div>
    </div>
  )
}

export default HideoutLevel;
