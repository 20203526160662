import React, {useCallback, useEffect, useRef} from 'react';
import './ShorelineMap.css';
import shorelineMapImage from '../../static/images/maps/Shoreline.png';
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";


const ShorelineMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();


  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${shorelineMapImage})`,
    backgroundSize: 'cover',
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div
      className="shorelineMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width="4729"
        height="3239"
        viewBox="0 0 4729 3239" // Must match W x H here and in shorelineMapContainer in CSS
      >
      {/* region paths */}
      <path
       id="path600"
       
       d="m 1054.9738,1975.477 a 33.656828,33.656873 0 0 0 -33.6557,33.6567 33.656828,33.656873 0 0 0 33.6557,33.6569 33.656828,33.656873 0 0 0 33.6568,-33.6569 33.656828,33.656873 0 0 0 -33.6568,-33.6567 z m 0,6.776 a 26.880785,26.880822 0 0 1 26.8808,26.8807 26.880785,26.880822 0 0 1 -26.8808,26.8797 26.880785,26.880822 0 0 1 -26.8796,-26.8785 26.880785,26.880822 0 0 1 26.8783,-26.8819 z"
       >
      <desc
         id="desc1">
        Objective IDs: 
35
      </desc>
    </path>
    <path
       id="path601"
       
       d="m 2029.7601,2896.9581 a 33.656828,33.656873 0 0 0 -33.6555,33.658 33.656828,33.656873 0 0 0 33.6555,33.6566 33.656828,33.656873 0 0 0 33.6567,-33.6566 33.656828,33.656873 0 0 0 -33.6567,-33.658 z m 0,6.776 a 26.880785,26.880822 0 0 1 26.8809,26.882 26.880785,26.880822 0 0 1 -26.8809,26.8784 26.880785,26.880822 0 0 1 -26.8796,-26.8784 26.880785,26.880822 0 0 1 26.8784,-26.882 z"
       >
      <desc
         id="desc2">
        Objective IDs: 
36
      </desc>
    </path>
    <path
       id="path602"
       
       d="m 1911.406,1548.5067 a 33.656828,33.656873 0 0 0 -33.6545,33.6569 33.656828,33.656873 0 0 0 33.6545,33.6582 33.656828,33.656873 0 0 0 33.6581,-33.6571 33.656828,33.656873 0 0 0 -33.6581,-33.658 z m 0,6.7761 a 26.880785,26.880822 0 0 1 26.8819,26.8808 26.880785,26.880822 0 0 1 -26.8819,26.8808 26.880785,26.880822 0 0 1 -26.8783,-26.8797 26.880785,26.880822 0 0 1 26.8783,-26.8819 z"
       >
      <desc
         id="desc3">
        Objective IDs: 
37
122
      </desc>
    </path>
    <path
       id="path603"
       
       d="m 1586.5641,1357.715 v 302.315 h 611.6513 v -302.3162 z m 6.8577,6.7856 h 597.9359 v 288.746 h -597.9359 z"
       >
      <desc
         id="desc4">
        Objective IDs:
70
      </desc>
    </path>
    <path
       id="path604"
       
       d="m 425.3699,2470.5127 a 22.487527,22.487556 0 0 0 -22.48634,22.489 22.487527,22.487556 0 0 0 22.48634,22.4864 22.487527,22.487556 0 0 0 22.48751,-22.4864 22.487527,22.487556 0 0 0 -22.48751,-22.4874 z m 0,4.5278 a 17.959756,17.959779 0 0 1 17.95975,17.9612 17.959756,17.959779 0 0 1 -17.95975,17.9584 17.959756,17.959779 0 0 1 -17.95975,-17.9584 17.959756,17.959779 0 0 1 17.95975,-17.9612 z"
       >
      <desc
         id="desc5">
        Objective IDs: 
107
200
      </desc>
    </path>
    <path
       id="path605"
       
       d="m 529.58196,2490.7668 a 22.487527,22.487556 0 0 0 -22.48631,22.4875 22.487527,22.487556 0 0 0 22.48631,22.4874 22.487527,22.487556 0 0 0 22.48755,-22.4874 22.487527,22.487556 0 0 0 -22.48755,-22.4875 z m 0,4.5274 a 17.959756,17.959779 0 0 1 17.96095,17.9601 17.959756,17.959779 0 0 1 -17.96095,17.9596 17.959756,17.959779 0 0 1 -17.95855,-17.9596 17.959756,17.959779 0 0 1 17.95855,-17.9601 z"
       >
      <desc
         id="desc6">
        Objective IDs: 
108
201
      </desc>
    </path>
    <path
       id="path606"
       
       d="m 1953.4936,1599.2425 a 22.487527,22.487556 0 0 0 -22.4862,22.4862 22.487527,22.487556 0 0 0 22.4862,22.4876 22.487527,22.487556 0 0 0 22.4876,-22.4876 22.487527,22.487556 0 0 0 -22.4876,-22.4874 z m 0,4.5266 a 17.959756,17.959779 0 0 1 17.9609,17.9596 17.959756,17.959779 0 0 1 -17.9609,17.96 17.959756,17.959779 0 0 1 -17.9585,-17.96 17.959756,17.959779 0 0 1 17.9585,-17.9596 z"
       >
      <desc
         id="desc7">
        Objective IDs: 
109
202
      </desc>
    </path>
    <path
       id="path607"
       
       d="m 4264.6773,959.77509 v 100.03701 h 76.0767 V 959.77509 Z m 6.2492,6.45802 h 63.5769 v 87.12589 h -63.5754 z"
       >
      <desc
         id="desc9">
        Objective IDs:
110
      </desc>
    </path>
    <path
       id="path608"
       
       d="m 1891.4416,1626.5448 a 22.487527,22.487556 0 0 0 -22.4865,22.4864 22.487527,22.487556 0 0 0 22.4865,22.4875 22.487527,22.487556 0 0 0 22.4875,-22.4875 22.487527,22.487556 0 0 0 -22.4875,-22.4864 z m 0,4.5266 a 17.959756,17.959779 0 0 1 17.9609,17.9598 17.959756,17.959779 0 0 1 -17.9609,17.9597 17.959756,17.959779 0 0 1 -17.9586,-17.9597 17.959756,17.959779 0 0 1 17.9586,-17.9598 z"
       >
      <desc
         id="desc10">
        Objective IDs: 
120
      </desc>
    </path>
    <path
       id="path609"
       
       d="m 1056.422,1919.3668 a 22.487527,22.487556 0 0 0 -22.4851,22.4876 22.487527,22.487556 0 0 0 22.4851,22.4874 22.487527,22.487556 0 0 0 22.4876,-22.4874 22.487527,22.487556 0 0 0 -22.4876,-22.4876 z m 0,4.5277 a 17.959756,17.959779 0 0 1 17.961,17.9599 17.959756,17.959779 0 0 1 -17.961,17.9586 17.959756,17.959779 0 0 1 -17.9586,-17.9586 17.959756,17.959779 0 0 1 17.9586,-17.9611 z"
       >
      <desc
         id="desc11">
        Objective IDs: 
121
      </desc>
    </path>
    <path
       id="path610"
       
       d="m 1050.1868,2000.3333 a 40.129019,40.129073 0 0 0 -40.1253,40.129 40.129019,40.129073 0 0 0 40.1253,40.1289 40.129019,40.129073 0 0 0 40.129,-40.1289 40.129019,40.129073 0 0 0 -40.129,-40.129 z m 0,8.079 a 32.048689,32.048732 0 0 1 32.0511,32.05 32.048689,32.048732 0 0 1 -32.0511,32.0487 32.048689,32.048732 0 0 1 -32.0475,-32.0487 32.048689,32.048732 0 0 1 32.0475,-32.05 z"
       >
      <desc
         id="desc12">
        Objective IDs: 
123
      </desc>
    </path>
    <path
       id="path611"
       
       d="m 2047.941,2881.2945 a 38.263863,38.263915 0 0 0 -38.2627,38.264 38.263863,38.263915 0 0 0 38.2627,38.2641 38.263863,38.263915 0 0 0 38.2638,-38.2641 38.263863,38.263915 0 0 0 -38.2638,-38.264 z m 0,7.7036 a 30.559449,30.559491 0 0 1 30.5606,30.5604 30.559449,30.559491 0 0 1 -30.5606,30.5584 30.559449,30.559491 0 0 1 -30.5595,-30.5584 30.559449,30.559491 0 0 1 30.5595,-30.5604 z"
       >
      <desc
         id="desc13">
        Objective IDs: 
124
      </desc>
    </path>
    <path
       id="path612"
       
       d="m 4117.1915,3091.6578 v 94.0098 h 76.8325 v -94.0098 z m 6.3129,6.0688 h 64.2082 v 81.8772 h -64.2082 z"
       >
      <desc
         id="desc14">
        Objective IDs:
125
      </desc>
    </path>
    <path
       id="path613"
       
       d="m 1992.3134,1557.8424 v 67.0132 h 171.2625 v -67.0132 z m 7.6095,6.8336 h 156.0413 v 53.3508 h -156.0413 z m -389.2296,-8.0743 v 67.0133 h 171.2627 v -67.0145 z m 7.6107,6.8324 h 156.0411 v 53.3521 H 1618.304 Z m -786.64916,332.4818 5.37687,220.2121 268.21929,-7.1557 -5.3768,-220.2132 z m 6.98624,8.8081 253.45422,-6.7616 4.9685,203.5195 -253.453,6.7627 z m 1123.15732,1037.0397 176.1916,155.2403 71.6071,-81.1001 -176.1914,-155.2402 z m 9.0028,1.4737 65.1926,-73.835 164.6006,145.0278 -65.1926,73.8342 z"
       >
      <desc
         id="desc18">
        Objective IDs:
129
579
      </desc>
    </path>
    <path
       id="path614"
       
       d="m 4134.6696,1538.3639 v 82.5292 h 75.549 v -82.5292 z m 6.2067,5.3263 h 63.1355 v 71.8801 h -63.1367 z"
       >
      <desc
         id="desc19">
        Objective IDs:
151
      </desc>
    </path>
    <path
       id="path615"
       
       d="m 2634.7031,2001.8272 a 38.263863,38.263915 0 0 0 -38.2627,38.2639 38.263863,38.263915 0 0 0 38.2627,38.2639 38.263863,38.263915 0 0 0 38.2639,-38.2639 38.263863,38.263915 0 0 0 -38.2639,-38.2639 z m 0,7.7032 a 30.559449,30.559491 0 0 1 30.5605,30.5607 30.559449,30.559491 0 0 1 -30.5605,30.5583 30.559449,30.559491 0 0 1 -30.5594,-30.5583 30.559449,30.559491 0 0 1 30.5594,-30.5607 z"
       >
      <desc
         id="desc20">
        Objective IDs: 
161
      </desc>
    </path>
    <path
       id="path616"
       
       
       d="m 3981.565,2494.902 a 14.761492,14.761514 0 0 0 -14.7602,14.7614 14.761492,14.761514 0 0 0 14.7602,14.7629 14.761492,14.761514 0 0 0 14.7628,-14.7629 14.761492,14.761514 0 0 0 -14.7628,-14.7614 z m 0,2.971 a 11.790219,11.790234 0 0 1 11.7903,11.7904 11.790219,11.790234 0 0 1 -11.7903,11.7902 11.790219,11.790234 0 0 1 -11.7891,-11.7902 11.790219,11.790234 0 0 1 11.7891,-11.7904 z m -56.866,-90.1686 v 122.2426 h 77.4104 v -122.2426 z m 6.359,7.8905 h 64.6908 v 106.4665 h -64.6908 z">
      <desc
         id="desc22">
        Objective IDs: 
162
      </desc>
    </path>
    <path
       id="path617"
       
       
       d="m 4030.5844,1384.5335 a 10.184481,10.184496 0 0 0 -10.1844,10.1858 10.184481,10.184496 0 0 0 10.1844,10.1858 10.184481,10.184496 0 0 0 10.1858,-10.1846 10.184481,10.184496 0 0 0 -10.1843,-10.187 z m 0,2.0514 a 8.134374,8.134385 0 0 1 8.1358,8.1344 8.134374,8.134385 0 0 1 -8.1358,8.1344 8.134374,8.134385 0 0 1 -8.1344,-8.1344 8.134374,8.134385 0 0 1 8.1344,-8.1344 z m -62.01,-19.1679 v 100.2163 h 77.2543 v -100.2151 z m 6.3473,6.4698 h 64.5612 v 87.2815 h -64.5612 z">
      <desc
         id="desc24">
        Objective IDs: 
164
      </desc>
    </path>
    <path
       id="path618"
       
       d="m 1719.2373,2815.1064 a 38.263863,38.263915 0 0 0 -38.2627,38.2636 38.263863,38.263915 0 0 0 38.2627,38.2639 38.263863,38.263915 0 0 0 38.2639,-38.2639 38.263863,38.263915 0 0 0 -38.2639,-38.2636 z m 0,7.703 a 30.559449,30.559491 0 0 1 30.5607,30.5606 30.559449,30.559491 0 0 1 -30.5607,30.5598 30.559449,30.559491 0 0 1 -30.5593,-30.5598 30.559449,30.559491 0 0 1 30.5593,-30.5606 z"
       >
      <desc
         id="desc25">
        Objective IDs: 
192
      </desc>
    </path>
    <path
       id="path619"
       
       d="m 1656.7118,1097.9313 a 31.484218,31.484262 0 0 0 -31.483,31.4855 31.484218,31.484262 0 0 0 31.483,31.4843 31.484218,31.484262 0 0 0 31.4854,-31.4843 31.484218,31.484262 0 0 0 -31.4854,-31.4855 z m 0,6.339 a 25.14534,25.145374 0 0 1 25.1465,25.1465 25.14534,25.145374 0 0 1 -25.1465,25.1442 25.14534,25.145374 0 0 1 -25.1455,-25.1442 25.14534,25.145374 0 0 1 25.1455,-25.1465 z"
       >
      <desc
         id="desc26">
        Objective IDs: 
208
      </desc>
    </path>
    <path
       id="path620"
       
       d="m 374.96745,2149.0158 a 22.487527,22.487556 0 0 0 -22.48634,22.4864 22.487527,22.487556 0 0 0 22.48634,22.4874 22.487527,22.487556 0 0 0 22.4875,-22.4874 22.487527,22.487556 0 0 0 -22.4875,-22.4864 z m 0,4.5264 a 17.959756,17.959779 0 0 1 17.96094,17.96 17.959756,17.959779 0 0 1 -17.96094,17.9595 17.959756,17.959779 0 0 1 -17.95975,-17.9595 17.959756,17.959779 0 0 1 17.95975,-17.96 z"
       >
      <desc
         id="desc27">
        Objective IDs: 
209
      </desc>
    </path>
    <path
       id="path621"
       
       d="m 2134.0934,2179.0401 a 38.263863,38.263915 0 0 0 -38.2615,38.2639 38.263863,38.263915 0 0 0 38.2627,38.264 38.263863,38.263915 0 0 0 38.2638,-38.264 38.263863,38.263915 0 0 0 -38.2638,-38.2639 z m 0,7.7046 a 30.559449,30.559491 0 0 1 30.5618,30.5604 30.559449,30.559491 0 0 1 -30.5606,30.5583 30.559449,30.559491 0 0 1 -30.5595,-30.5583 30.559449,30.559491 0 0 1 30.5595,-30.5604 z"
       >
      <desc
         id="desc28">
        Objective IDs: 
210
      </desc>
    </path>
    <path
       id="path622"
       
       d="m 2599.1946,1447.3569 a 38.263863,38.263915 0 0 0 -38.2627,38.2639 38.263863,38.263915 0 0 0 38.2627,38.264 38.263863,38.263915 0 0 0 38.2638,-38.264 38.263863,38.263915 0 0 0 -38.2638,-38.2639 z m 0,7.7032 a 30.559449,30.559491 0 0 1 30.5606,30.5607 30.559449,30.559491 0 0 1 -30.5606,30.5596 30.559449,30.559491 0 0 1 -30.5596,-30.5596 30.559449,30.559491 0 0 1 30.5596,-30.5607 z"
       >
      <desc
         id="desc29">
        Objective IDs: 
211
      </desc>
    </path>
    <path
       id="path623"
       
       d="m 1093.5593,1354.4905 a 36.449153,36.449203 0 0 0 -36.4467,36.4504 36.449153,36.449203 0 0 0 36.4467,36.4479 36.449153,36.449203 0 0 0 36.4505,-36.4491 36.449153,36.449203 0 0 0 -36.4505,-36.4492 z m 0,7.3382 a 29.109842,29.109881 0 0 1 29.1123,29.1122 29.109842,29.109881 0 0 1 -29.1123,29.1087 29.109842,29.109881 0 0 1 -29.1086,-29.1099 29.109842,29.109881 0 0 1 29.1086,-29.111 z"
       >
      <desc
         id="desc30">
        Objective IDs: 
212
      </desc>
    </path>
    <path
       id="path624"
       
       d="m 1847.4534,1370.9237 a 31.484218,31.484262 0 0 0 -31.4831,31.4843 31.484218,31.484262 0 0 0 31.4831,31.4854 31.484218,31.484262 0 0 0 31.4854,-31.4854 31.484218,31.484262 0 0 0 -31.4854,-31.4843 z m 0,6.3388 a 25.14534,25.145374 0 0 1 25.1466,25.1455 25.14534,25.145374 0 0 1 -25.1466,25.1453 25.14534,25.145374 0 0 1 -25.1442,-25.1453 25.14534,25.145374 0 0 1 25.1442,-25.1455 z"
       >
      <desc
         id="desc31">
        Objective IDs: 
213
      </desc>
    </path>
    <path
       id="path625"
       
       d="m 2718.9577,2855.9736 a 31.484218,31.484262 0 0 0 -31.4831,31.4843 31.484218,31.484262 0 0 0 31.4831,31.4853 31.484218,31.484262 0 0 0 31.4854,-31.4853 31.484218,31.484262 0 0 0 -31.4854,-31.4843 z m 0,6.3391 a 25.14534,25.145374 0 0 1 25.1464,25.1452 25.14534,25.145374 0 0 1 -25.1464,25.1453 25.14534,25.145374 0 0 1 -25.1455,-25.1453 25.14534,25.145374 0 0 1 25.1455,-25.1467 z"
       >
      <desc
         id="desc32">
        Objective IDs: 
214
      </desc>
    </path>
    <path
       id="path626"
       
       d="m 591.73622,1643.0164 a 34.276544,34.276591 0 0 0 -34.27654,34.2765 34.276544,34.276591 0 0 0 34.27654,34.2779 34.276544,34.276591 0 0 0 34.27653,-34.2767 34.276544,34.276591 0 0 0 -34.27653,-34.2777 z m 0,6.8997 a 27.375598,27.375634 0 0 1 27.37679,27.3768 27.375598,27.375634 0 0 1 -27.37679,27.3757 27.375598,27.375634 0 0 1 -27.37561,-27.3757 27.375598,27.375634 0 0 1 27.37561,-27.3768 z"
       >
      <desc
         id="desc33">
        Objective IDs: 
217
467
      </desc>
    </path>
    <path
       id="path627"
       
       d="m 4416.9571,2436.4247 v 85.6979 h 77.42 v -85.6979 z m 6.3607,5.5313 h 64.7002 v 74.6391 h -64.7002 z"
       >
      <desc
         id="desc34">
        Objective IDs:
218
      </desc>
    </path>
    <path
       id="path628"
       
       d="m 1892.1622,1634.26 a 40.09419,40.094244 0 0 0 -40.093,40.0942 40.09419,40.094244 0 0 0 40.093,40.0955 40.09419,40.094244 0 0 0 40.0955,-40.0955 40.09419,40.094244 0 0 0 -40.0955,-40.0942 z m 0,8.0708 a 32.022268,32.022309 0 0 1 32.0234,32.0234 32.022268,32.022309 0 0 1 -32.0234,32.0212 32.022268,32.022309 0 0 1 -32.0211,-32.0212 32.022268,32.022309 0 0 1 32.0211,-32.0234 z"
       >
      <desc
         id="desc35">
        Objective IDs: 
238
      </desc>
    </path>
    <path
       id="path629"
       
       d="m 1933.306,1649.7194 v 31.0435 h 95.7606 l 0.1092,-31.154 z m 4.8281,5.1583 86.3279,0.1092 -0.1082,20.9503 -86.2197,-0.3292 z"
    >
      <desc
         id="desc36">
        Objective IDs:
239
      </desc>
    </path>
    <path
       id="path630"
       
       d="m 4344.391,2829.2591 v 85.0154 h 77.2229 v -85.0154 z m 6.3435,5.4887 h 64.5347 v 74.0418 h -64.5347 z"
       >
      <desc
         id="desc37">
        Objective IDs:
245
      </desc>
    </path>
    <path
       id="path631"
       
       d="m 3905.5811,1367.3997 v 100.1527 h 77.0044 v -100.1515 z m 6.3268,6.4651 h 64.3522 v 87.2275 h -64.3522 z"
       >
      <desc
         id="desc38">
        Objective IDs:
246
      </desc>
    </path>
    <path
       id="path632"
       
       d="m 3925.5516,2409.2117 v 99.5934 h 76.3235 v -99.5934 z m 6.2703,6.4278 h 63.7841 v 86.741 h -63.7841 z"
       >
      <desc
         id="desc39">
        Objective IDs:
254
      </desc>
    </path>
    <path
       id="path633"
       
       d="m 4244.1652,2986.2991 v 75.8594 h 134.5095 v -75.8594 z m 3.9283,4.3764 h 126.6513 v 67.1052 h -126.6513 z"
       >
      <desc
         id="desc41">
        Objective IDs:
255
      </desc>
    </path>
    <path
       id="path634"
       
       
       d="m 1814.6148,1503.3209 a 54.548224,54.548298 0 0 0 -54.5458,54.5483 54.548224,54.548298 0 0 0 54.5458,54.5482 54.548224,54.548298 0 0 0 54.5483,-54.5494 54.548224,54.548298 0 0 0 -54.5483,-54.5471 z m 0,10.9819 a 43.565078,43.565136 0 0 1 43.5675,43.5664 43.565078,43.565136 0 0 1 -43.5675,43.5639 43.565078,43.565136 0 0 1 -43.564,-43.5639 43.565078,43.565136 0 0 1 43.564,-43.5676 z m 2078.3788,390.5394 a 38.263863,38.263915 0 0 0 -38.2612,38.2639 38.263863,38.263915 0 0 0 38.2628,38.2638 38.263863,38.263915 0 0 0 38.2636,-38.2638 38.263863,38.263915 0 0 0 -38.2636,-38.2639 z m 0,7.7032 a 30.559449,30.559491 0 0 1 30.562,30.5607 30.559449,30.559491 0 0 1 -30.5604,30.5583 30.559449,30.559491 0 0 1 -30.5598,-30.5583 30.559449,30.559491 0 0 1 30.5598,-30.5607 z">
      <desc
         id="desc43">
        Objective IDs: 
256
      </desc>
    </path>
    <path
       id="path635"
       
       d="m 689.05556,2750.7466 a 39.861194,39.861246 0 0 0 -39.86118,39.8611 39.861194,39.861246 0 0 0 39.86118,39.8623 39.861194,39.861246 0 0 0 39.86119,-39.8611 39.861194,39.861246 0 0 0 -39.86119,-39.8623 z m 0,8.0251 a 31.836113,31.836155 0 0 1 31.8385,31.8372 31.836113,31.836155 0 0 1 -31.8385,31.835 31.836113,31.836155 0 0 1 -31.8349,-31.835 31.836113,31.836155 0 0 1 31.8349,-31.8387 z"
       >
      <desc
         id="desc44">
        Objective IDs: 
262
      </desc>
    </path>
    <path
       id="path636"
       
       d="m 1098.3664,2574.3043 a 22.487527,22.487556 0 0 0 -22.4863,22.4875 22.487527,22.487556 0 0 0 22.4863,22.4878 22.487527,22.487556 0 0 0 22.4876,-22.4878 22.487527,22.487556 0 0 0 -22.4876,-22.4875 z m 0,4.5278 a 17.959756,17.959779 0 0 1 17.9611,17.9597 17.959756,17.959779 0 0 1 -17.9611,17.96 17.959756,17.959779 0 0 1 -17.9597,-17.96 17.959756,17.959779 0 0 1 17.9597,-17.9597 z"
       >
      <desc
         id="desc45">
        Objective IDs: 
263
      </desc>
    </path>
    <path
       id="path637"
       
       d="m 2786.8444,241.91877 v 66.25596 h 55.7265 v -66.25596 z m 8.6269,7.01958 h 38.4746 v 52.21489 h -38.4746 z M 1893.2622,1389.9323 a 67.736407,67.736498 0 0 0 -67.7362,67.7364 67.736407,67.736498 0 0 0 67.7362,67.7384 67.736407,67.736498 0 0 0 67.7364,-67.7364 67.736407,67.736498 0 0 0 -67.7364,-67.7384 z m 0,13.6368 a 54.09905,54.099122 0 0 1 54.0997,54.0996 54.09905,54.099122 0 0 1 -54.0997,54.0978 54.09905,54.099122 0 0 1 -54.0995,-54.0978 54.09905,54.099122 0 0 1 54.0995,-54.0996 z"
       >
      <desc
         id="desc17">Objective IDs:
263</desc>
    </path>
    <path
       id="path638"
       
       d="m 4603.966,2539.5296 v 86.0645 h 77.4201 v -86.0645 z m 6.3605,5.5559 h 64.7003 v 74.9572 h -64.7003 z"
       >
      <desc
         id="desc48">
        Objective IDs:
266
      </desc>
    </path>
    <path
       id="path639"
       
       d="m 1833.4465,1526.5986 a 33.656828,33.656873 0 0 0 -33.6556,33.6582 33.656828,33.656873 0 0 0 33.6556,33.6567 33.656828,33.656873 0 0 0 33.6569,-33.6567 33.656828,33.656873 0 0 0 -33.6569,-33.6582 z m 0,6.7761 a 26.880785,26.880822 0 0 1 26.8808,26.8821 26.880785,26.880822 0 0 1 -26.8808,26.8784 26.880785,26.880822 0 0 1 -26.8795,-26.8784 26.880785,26.880822 0 0 1 26.8783,-26.8821 z"
       >
      <desc
         id="desc49">
        Objective IDs: 
357
365
      </desc>
    </path>
    <path
       id="path640"
       
       d="m 2455.1309,2325.9214 a 50.988461,50.988529 0 0 0 -50.9862,50.9885 50.988461,50.988529 0 0 0 50.9862,50.9885 50.988461,50.988529 0 0 0 50.9897,-50.9885 50.988461,50.988529 0 0 0 -50.9897,-50.9885 z m 0,10.2641 a 40.722313,40.722368 0 0 1 40.7246,40.7244 40.722313,40.722368 0 0 1 -40.7246,40.7214 40.722313,40.722368 0 0 1 -40.7211,-40.7214 40.722313,40.722368 0 0 1 40.7211,-40.7244 z"
       >
      <desc
         id="desc50">
        Objective IDs: 
358
364
      </desc>
    </path>
    <path
       id="path641"
       
       d="m 2987.0348,1950.76 a 38.263863,38.263915 0 0 0 -38.2628,38.2639 38.263863,38.263915 0 0 0 38.264,38.264 38.263863,38.263915 0 0 0 38.2639,-38.264 38.263863,38.263915 0 0 0 -38.2639,-38.2639 z m 0,7.7034 a 30.559449,30.559491 0 0 1 30.5605,30.5605 30.559449,30.559491 0 0 1 -30.5605,30.5596 30.559449,30.559491 0 0 1 -30.5594,-30.5596 30.559449,30.559491 0 0 1 30.5594,-30.5605 z"
       >
      <desc
         id="desc53">
        Objective IDs: 
366
      </desc>
    </path>
    <path
       id="path642"
       
       d="m 305.02974,1646.5257 a 34.276544,34.276591 0 0 0 -34.27533,34.2778 34.276544,34.276591 0 0 0 34.27654,34.2765 34.276544,34.276591 0 0 0 34.27659,-34.2765 34.276544,34.276591 0 0 0 -34.27659,-34.2766 z m 0,6.901 a 27.375598,27.375634 0 0 1 27.37803,27.3768 27.375598,27.375634 0 0 1 -27.37803,27.3756 27.375598,27.375634 0 0 1 -27.37436,-27.3756 27.375598,27.375634 0 0 1 27.37436,-27.3768 z"
       >
      <desc
         id="desc54">
        Objective IDs: 
466
      </desc>
    </path>
    <path
       id="path643"
       
       d="m 362.57173,1451.8684 a 34.276544,34.276591 0 0 0 -34.27657,34.2777 34.276544,34.276591 0 0 0 34.27657,34.2767 34.276544,34.276591 0 0 0 34.27653,-34.2767 34.276544,34.276591 0 0 0 -34.27653,-34.2765 z m 0,6.9009 a 27.375598,27.375634 0 0 1 27.37677,27.3768 27.375598,27.375634 0 0 1 -27.37677,27.3757 27.375598,27.375634 0 0 1 -27.37557,-27.3757 27.375598,27.375634 0 0 1 27.37557,-27.3768 z"
       >
      <desc
         id="desc55">
        Objective IDs: 
468
      </desc>
    </path>
    <path
       id="path644"
       
       d="m 4388.2264,973.20753 v 85.78757 h 77.7407 v -85.78757 z m 6.3855,5.53663 h 64.9693 v 74.71784 h -64.9693 z"
       >
      <desc
         id="desc56">
        Objective IDs:
469
      </desc>
    </path>
    <path
       id="path645"
       
       d="m 2375.7928,2304.1193 v 143.963 h 179.0199 v -143.9641 z m 8.0155,8.508 h 162.9889 v 126.9457 h -162.9877 z"
       >
      <desc
         id="desc57">Objective IDs:
507
508
509
      </desc>
    </path>
    <path
       id="path647"
       
       d="m 732.60006,1360.0773 a 34.276544,34.276591 0 0 0 -34.27654,34.2766 34.276544,34.276591 0 0 0 34.27654,34.2776 34.276544,34.276591 0 0 0 34.27654,-34.2764 34.276544,34.276591 0 0 0 -34.27654,-34.2778 z m 0,6.8997 a 27.375598,27.375634 0 0 1 27.37678,27.3769 27.375598,27.375634 0 0 1 -27.37678,27.3756 27.375598,27.375634 0 0 1 -27.37557,-27.3756 27.375598,27.375634 0 0 1 27.37557,-27.3769 z"
       >
      <desc
         id="desc8">Objective IDs: 
619</desc>
    </path>
    <path
       id="path648"
       
       d="m 1654.4769,1050.4686 a 31.484218,31.484262 0 0 0 -31.4829,31.4856 31.484218,31.484262 0 0 0 31.4829,31.4842 31.484218,31.484262 0 0 0 31.4855,-31.4842 31.484218,31.484262 0 0 0 -31.4855,-31.4856 z m 0,6.3389 a 25.14534,25.145374 0 0 1 25.1466,25.1467 25.14534,25.145374 0 0 1 -25.1466,25.1441 25.14534,25.145374 0 0 1 -25.1452,-25.1441 25.14534,25.145374 0 0 1 25.1452,-25.1467 z"
       >
      <desc
         id="desc15">Objective IDs: 
246
      </desc>
    </path>
    <path
       id="path649"
       
       d="M 2324.6189,951.20496 V 2068.1657 h 707.6018 V 951.20496 Z m 12.6701,14.61916 h 682.2617 V 2055.4938 H 2337.289 Z"
       >
      <desc
         id="desc23">Objective IDs:
629</desc>
    </path>
    <path
       id="path650"
       
       d="m 2812.7663,1130.8543 a 163.67071,163.67092 0 0 0 -163.6629,163.6711 163.67071,163.67092 0 0 0 163.6629,163.6707 163.67071,163.67092 0 0 0 163.6749,-163.6707 163.67071,163.67092 0 0 0 -163.6749,-163.6711 z m 0,32.9465 a 130.71683,130.71702 0 0 1 130.7247,130.7246 130.71683,130.71702 0 0 1 -130.7247,130.7132 130.71683,130.71702 0 0 1 -130.7128,-130.7132 130.71683,130.71702 0 0 1 130.7128,-130.7246 z"
       >
      <desc
         id="desc21">Objective IDs: 
628
      </desc>
    </path>
        {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default ShorelineMap;
