// Component for the navigation bar at the top of the page
// This component is used to navigate between the different pages of the application
// Nav bar has buttons that will expand and collapse when clicked
// The buttons will be Player, Traders, Hideout, and Settings
// The buttons will have icons and text
// The buttons will use Chakra UI components
// Styles will be in NavigationBar.css

import React, { useState } from 'react';
import {Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure} from '@chakra-ui/react';
import './NavigationBar.css';
import Settings from "../Settings/Settings";
import LoyaltyLevel from "../LoyaltyLevel/LoyaltyLevel";
import HideoutLevel from "../HideoutLevel/HideoutLevel";

function NavigationBar({ onMapSelect, faction, setFaction, onAccountReset }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <div className="navBar">
      <Menu>
        <MenuButton
          as={Button}
          variant="ghost"
          onClick={onToggle}
        >
          Maps
        </MenuButton>
          <MenuList className="customMenuList">
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Customs')}>Customs</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Factory')}>Factory</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Ground Zero')}>Ground Zero</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Interchange')}>Interchange</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Labs')}>Labs</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Lighthouse')}>Lighthouse</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Reserve')}>Reserve</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Shoreline')}>Shoreline</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Streets')}>Streets</MenuItem>
            <MenuItem className="customMenuItem" onClick={() => onMapSelect('Woods')}>Woods</MenuItem>
          </MenuList>
      </Menu>
      <Menu>
       <MenuButton
          as={Button}
          variant="ghost"
          onClick={onToggle}
        >
          Traders
        </MenuButton>
          <MenuList className="customMenuList">
              <LoyaltyLevel />
          </MenuList>
      </Menu>
      <Menu>
       <MenuButton
          as={Button}
          variant="ghost"
          onClick={onToggle}
        >
          Hideout
        </MenuButton>
          <MenuList className="customMenuList">
              <HideoutLevel />
          </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          as={Button}
          variant="ghost"
          onClick={onToggle}
        >
          Settings
        </MenuButton>
        {/* Menu with items from Settings.js component */}
        <MenuList className="customMenuList">
          <Settings
            faction={faction}
            setFaction={setFaction}
            onAccountReset={onAccountReset}
          />
        </MenuList>
      </Menu>
    </div>
  );
}

export default NavigationBar;