// Component that displays the loyalty levels of each trader. The user can click on a trader to increase their loyalty level.

import React from 'react';
import './LoyaltyLevel.css';
import praporImage from "../../static/images/traders/Prapor.PNG";
import therapistImage from "../../static/images/traders/Therapist.PNG"
import fenceImage from "../../static/images/traders/Fence.PNG"
import skierImage from "../../static/images/traders/Skier.PNG"
import peacekeeperImage from "../../static/images/traders/Peacekeeper.PNG"
import mechanicImage from "../../static/images/traders/Mechanic.PNG"
import ragmanImage from "../../static/images/traders/Ragman.PNG"
import jaegerImage from "../../static/images/traders/Jaeger.PNG"
import LoyaltyLevelIcon from "../LoyaltyLevelIcon/LoyaltyLevelIcon";
import { useUser } from '../../contexts/UserContext';


function LoyaltyLevel() {
  const { user, updateLoyaltyLevels } = useUser();

  const updateLoyalty = (trader, currentLoyalty) => {
    let numericCurrentLoyalty = Number(currentLoyalty);

    let newLoyalty = trader === 'Fence' ?
      (numericCurrentLoyalty === 1 ? 4 : 1) :
      (numericCurrentLoyalty % 4) + 1;

    const repFields = {
      PraporRep: Number(user.PraporRep),
      TherapistRep: Number(user.TherapistRep),
      FenceRep: Number(user.FenceRep),
      SkierRep: Number(user.SkierRep),
      PeacekeeperRep: Number(user.PeacekeeperRep),
      MechanicRep: Number(user.MechanicRep),
      RagmanRep: Number(user.RagmanRep),
      JaegerRep: Number(user.JaegerRep),
    };

    let updatedLevels = {
      ...repFields,
      [`${trader}Rep`]: newLoyalty
    };

    updateLoyaltyLevels(updatedLevels);
  };



  return (
    <div className="loyaltyLevelBox">
      <div className="traderWrap" onClick={() => updateLoyalty('Prapor', user.PraporRep)}>
        <img className="traderImage" src={praporImage} alt="Prapor" />
        <LoyaltyLevelIcon level={user.PraporRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Therapist', user.TherapistRep)}>
        <img className="traderImage" src={therapistImage} alt="Therapist" />
        <LoyaltyLevelIcon level={user.TherapistRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Fence', user.FenceRep)}>
        <img className="traderImage" src={fenceImage} alt="Fence" />
        <LoyaltyLevelIcon level={user.FenceRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Skier', user.SkierRep)}>
        <img className="traderImage" src={skierImage} alt="Skier" />
        <LoyaltyLevelIcon level={user.SkierRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Peacekeeper', user.PeacekeeperRep)}>
        <img className="traderImage" src={peacekeeperImage} alt="Peacekeeper" />
        <LoyaltyLevelIcon level={user.PeacekeeperRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Mechanic', user.MechanicRep)}>
        <img className="traderImage" src={mechanicImage} alt="Mechanic" />
        <LoyaltyLevelIcon level={user.MechanicRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Ragman', user.RagmanRep)}>
        <img className="traderImage" src={ragmanImage} alt="Ragman" />
        <LoyaltyLevelIcon level={user.RagmanRep} />
      </div>
      <div className="traderWrap" onClick={() => updateLoyalty('Jaeger', user.JaegerRep)}>
        <img className="traderImage" src={jaegerImage} alt="Jaeger" />
        <LoyaltyLevelIcon level={user.JaegerRep} />
      </div>
    </div>
  )
}


export default LoyaltyLevel;