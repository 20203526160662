import React, {useCallback, useEffect, useRef} from 'react';
import './WoodsMap.css';
import woodsMapImage from "../../static/images/maps/Woods.png";
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";

const WoodsMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();


  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${woodsMapImage})`,
    backgroundSize: "cover",
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };


  return (
    <div
      className="woodsMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width={4000}
        height={3913.3333}
        viewBox="0 0 4000 3913.3333"
     >
      {/* region paths */}
      <path
        id="path800"
        d="m 1329.9792,919.01243 a 44.685099,44.685099 0 0 0 -44.6831,44.68549 44.685099,44.685099 0 0 0 44.6831,44.68538 44.685099,44.685099 0 0 0 44.6856,-44.68538 44.685099,44.685099 0 0 0 -44.6856,-44.68549 z m 0,8.99599 a 35.688099,35.688099 0 0 1 35.6897,35.6895 35.688099,35.688099 0 0 1 -35.6897,35.68747 35.688099,35.688099 0 0 1 -35.6874,-35.68747 35.688099,35.688099 0 0 1 35.6874,-35.6895 z"
      >
        <desc id="desc1">{"Objective IDs: \r\n4"}</desc>
      </path>
      <path
        id="path801"
        d="m 1086.2557,1346.0945 a 44.685099,44.685099 0 0 0 -44.6829,44.6855 44.685099,44.685099 0 0 0 44.6829,44.6855 44.685099,44.685099 0 0 0 44.6856,-44.6855 44.685099,44.685099 0 0 0 -44.6856,-44.6855 z m 0,8.9961 a 35.688099,35.688099 0 0 1 35.6896,35.6894 35.688099,35.688099 0 0 1 -35.6896,35.6874 35.688099,35.688099 0 0 1 -35.6874,-35.6874 35.688099,35.688099 0 0 1 35.6874,-35.6894 z"
      >
        <desc id="desc2">{"Objective IDs: \r\n5"}</desc>
      </path>
      <path
        id="path802"
        d="m 2386.9348,3083.6007 a 145.04354,145.04354 0 0 0 -145.037,145.0443 145.04354,145.04354 0 0 0 145.037,145.0449 145.04354,145.04354 0 0 0 145.0447,-145.0449 145.04354,145.04354 0 0 0 -145.0447,-145.0443 z m 0,29.1998 a 115.84016,115.84016 0 0 1 115.8452,115.8445 115.84016,115.84016 0 0 1 -115.8452,115.8381 115.84016,115.84016 0 0 1 -115.8376,-115.8381 115.84016,115.84016 0 0 1 115.8376,-115.8445 z"
      >
        <desc id="desc3">{"Objective IDs: \r\n69"}</desc>
      </path>
      <path
        id="path803"
        d="m 1873.6412,2321.7431 a 44.685099,44.685099 0 0 0 -44.6832,44.6856 44.685099,44.685099 0 0 0 44.6832,44.6856 44.685099,44.685099 0 0 0 44.6855,-44.6856 44.685099,44.685099 0 0 0 -44.6855,-44.6856 z m 0,8.9961 a 35.688099,35.688099 0 0 1 35.6897,35.6895 35.688099,35.688099 0 0 1 -35.6897,35.6874 35.688099,35.688099 0 0 1 -35.6873,-35.6874 35.688099,35.688099 0 0 1 35.6873,-35.6895 z"
      >
        <desc id="desc4">{"Objective IDs: \r\n105"}</desc>
      </path>
      <path
        id="path804"
        d="m 2124.9104,3159.3068 a 44.685099,44.685099 0 0 0 -44.6832,44.6857 44.685099,44.685099 0 0 0 44.6832,44.6854 44.685099,44.685099 0 0 0 44.6855,-44.6854 44.685099,44.685099 0 0 0 -44.6855,-44.6857 z m 0,8.9963 a 35.688099,35.688099 0 0 1 35.6894,35.6894 35.688099,35.688099 0 0 1 -35.6894,35.6875 35.688099,35.688099 0 0 1 -35.6875,-35.6875 35.688099,35.688099 0 0 1 35.6875,-35.6894 z"
      >
        <desc id="desc5">{"Objective IDs: \r\n113"}</desc>
      </path>
      <path
        id="path805"
        d="m 1224.3326,2348.775 a 44.685099,44.685099 0 0 0 -44.683,44.6853 44.685099,44.685099 0 0 0 44.683,44.6854 44.685099,44.685099 0 0 0 44.6855,-44.6854 44.685099,44.685099 0 0 0 -44.6855,-44.6853 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.6896,35.6893 35.688099,35.688099 0 0 1 -35.6896,35.6874 35.688099,35.688099 0 0 1 -35.6872,-35.6874 35.688099,35.688099 0 0 1 35.6872,-35.6893 z"
      >
        <desc id="desc6">{"Objective IDs: \r\n160"}</desc>
      </path>
      <path
        id="path806"
        d="m 1724.23,2422.7222 a 44.685099,44.685099 0 0 0 -44.6831,44.6849 44.685099,44.685099 0 0 0 44.6831,44.6858 44.685099,44.685099 0 0 0 44.6857,-44.6858 44.685099,44.685099 0 0 0 -44.6857,-44.6849 z m 0,8.9956 a 35.688099,35.688099 0 0 1 35.6897,35.6893 35.688099,35.688099 0 0 1 -35.6897,35.6875 35.688099,35.688099 0 0 1 -35.6874,-35.6875 35.688099,35.688099 0 0 1 35.6874,-35.6893 z"
      >
        <desc id="desc7">{"Objective IDs: \r\n163"}</desc>
      </path>
      <path
        id="path807"
        d="m 1771.7673,2760.0111 a 44.685099,44.685099 0 0 0 -44.683,44.6855 44.685099,44.685099 0 0 0 44.683,44.6854 44.685099,44.685099 0 0 0 44.6854,-44.6854 44.685099,44.685099 0 0 0 -44.6854,-44.6855 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.69,35.6895 35.688099,35.688099 0 0 1 -35.69,35.6873 35.688099,35.688099 0 0 1 -35.687,-35.6873 35.688099,35.688099 0 0 1 35.687,-35.6895 z"
      >
        <desc id="desc8">{"Objective IDs: \r\n176"}</desc>
      </path>
      <path
        id="path808"
        d="m 1874.7652,2338.9657 a 44.685099,44.685099 0 0 0 -44.6831,44.6854 44.685099,44.685099 0 0 0 44.6831,44.6853 44.685099,44.685099 0 0 0 44.6856,-44.6853 44.685099,44.685099 0 0 0 -44.6856,-44.6854 z m 0,8.9958 a 35.688099,35.688099 0 0 1 35.6897,35.6896 35.688099,35.688099 0 0 1 -35.6897,35.6873 35.688099,35.688099 0 0 1 -35.6874,-35.6873 35.688099,35.688099 0 0 1 35.6874,-35.6896 z"
      >
        <desc id="desc9">{"Objective IDs: \r\n176"}</desc>
      </path>
      <path
        id="path809"
        d="m 1301.2982,2546.0929 a 44.685099,44.685099 0 0 0 -44.6832,44.6853 44.685099,44.685099 0 0 0 44.6832,44.6857 44.685099,44.685099 0 0 0 44.6852,-44.6857 44.685099,44.685099 0 0 0 -44.6852,-44.6853 z m 0,8.9957 a 35.688099,35.688099 0 0 1 35.6896,35.6896 35.688099,35.688099 0 0 1 -35.6896,35.6875 35.688099,35.688099 0 0 1 -35.6873,-35.6875 35.688099,35.688099 0 0 1 35.6873,-35.6896 z"
      >
        <desc id="desc10">{"Objective IDs: \r\n219"}</desc>
      </path>
      <path
        id="path810"
        d="m 2103.7749,568.76323 a 44.685099,44.685099 0 0 0 -44.6834,44.68544 44.685099,44.685099 0 0 0 44.6834,44.68544 44.685099,44.685099 0 0 0 44.6857,-44.68544 44.685099,44.685099 0 0 0 -44.6857,-44.68544 z m 0,8.99594 a 35.688099,35.688099 0 0 1 35.6894,35.6895 35.688099,35.688099 0 0 1 -35.6894,35.68739 35.688099,35.688099 0 0 1 -35.6876,-35.68739 35.688099,35.688099 0 0 1 35.6876,-35.6895 z"
      >
        <desc id="desc11">{"Objective IDs: \r\n220"}</desc>
      </path>
      <path
        id="path811"
        d="m 1799.6863,2810.9441 a 44.685099,44.685099 0 0 0 -44.6833,44.6855 44.685099,44.685099 0 0 0 44.6833,44.6856 44.685099,44.685099 0 0 0 44.6856,-44.6856 44.685099,44.685099 0 0 0 -44.6856,-44.6855 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.6895,35.6895 35.688099,35.688099 0 0 1 -35.6895,35.687 35.688099,35.688099 0 0 1 -35.6874,-35.687 35.688099,35.688099 0 0 1 35.6874,-35.6895 z"
      >
        <desc id="desc12">{"Objective IDs: \r\n309"}</desc>
      </path>
      <path
        id="path812"
        d="m 893.83485,2300.483 a 44.685099,44.685099 0 0 0 -44.68317,44.6854 44.685099,44.685099 0 0 0 44.68317,44.6856 44.685099,44.685099 0 0 0 44.68545,-44.6856 44.685099,44.685099 0 0 0 -44.68545,-44.6854 z m 0,8.9959 a 35.688099,35.688099 0 0 1 35.68961,35.6895 35.688099,35.688099 0 0 1 -35.68961,35.6875 35.688099,35.688099 0 0 1 -35.68743,-35.6875 35.688099,35.688099 0 0 1 35.68743,-35.6895 z"
      >
        <desc id="desc13">{"Objective IDs: \r\n317"}</desc>
      </path>
      <path
        id="path813"
        d="m 2761.3754,2602.3076 a 44.685099,44.685099 0 0 0 -44.6832,44.6859 44.685099,44.685099 0 0 0 44.6832,44.6851 44.685099,44.685099 0 0 0 44.6857,-44.6851 44.685099,44.685099 0 0 0 -44.6857,-44.6859 z m 0,8.9962 a 35.688099,35.688099 0 0 1 35.6899,35.6897 35.688099,35.688099 0 0 1 -35.6899,35.687 35.688099,35.688099 0 0 1 -35.6872,-35.687 35.688099,35.688099 0 0 1 35.6872,-35.6897 z"
      >
        <desc id="desc14">{"Objective IDs: \r\n318"}</desc>
      </path>
      <path
        id="path814"
        d="m 2423.3318,1961.3075 a 44.685099,44.685099 0 0 0 -44.6832,44.6853 44.685099,44.685099 0 0 0 44.6832,44.6855 44.685099,44.685099 0 0 0 44.6855,-44.6855 44.685099,44.685099 0 0 0 -44.6855,-44.6853 z m 0,8.9958 a 35.688099,35.688099 0 0 1 35.6896,35.6895 35.688099,35.688099 0 0 1 -35.6896,35.6874 35.688099,35.688099 0 0 1 -35.6873,-35.6874 35.688099,35.688099 0 0 1 35.6873,-35.6895 z"
      >
        <desc id="desc15">{"Objective IDs: \r\n319"}</desc>
      </path>
      <path
        id="path815"
        d="m 2561.3893,2570.628 a 44.685099,44.685099 0 0 0 -44.6832,44.6853 44.685099,44.685099 0 0 0 44.6832,44.6856 44.685099,44.685099 0 0 0 44.6853,-44.6856 44.685099,44.685099 0 0 0 -44.6853,-44.6853 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.6898,35.6893 35.688099,35.688099 0 0 1 -35.6898,35.6876 35.688099,35.688099 0 0 1 -35.6871,-35.6876 35.688099,35.688099 0 0 1 35.6871,-35.6893 z"
      >
        <desc id="desc16">{"Objective IDs: \r\n351"}</desc>
      </path>
      <path
        id="path816"
        d="m 2930.8768,2589.836 a 44.685099,44.685099 0 0 0 -44.6833,44.6855 44.685099,44.685099 0 0 0 44.6833,44.6855 44.685099,44.685099 0 0 0 44.6854,-44.6855 44.685099,44.685099 0 0 0 -44.6854,-44.6855 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.6897,35.6895 35.688099,35.688099 0 0 1 -35.6897,35.6875 35.688099,35.688099 0 0 1 -35.6873,-35.6875 35.688099,35.688099 0 0 1 35.6873,-35.6895 z"
      >
        <desc id="desc17">{"Objective IDs: \r\n446"}</desc>
      </path>
      <path
        id="path817"
        d="m 667.26612,2717.0892 a 44.685099,44.685099 0 0 0 -44.68313,44.6855 44.685099,44.685099 0 0 0 44.68313,44.6855 44.685099,44.685099 0 0 0 44.68553,-44.6855 44.685099,44.685099 0 0 0 -44.68553,-44.6855 z m 0,8.9957 a 35.688099,35.688099 0 0 1 35.68967,35.6898 35.688099,35.688099 0 0 1 -35.68967,35.6875 35.688099,35.688099 0 0 1 -35.68735,-35.6875 35.688099,35.688099 0 0 1 35.68735,-35.6898 z"
      >
        <desc id="desc18">{"Objective IDs: \r\n447"}</desc>
      </path>
      <path
        id="path818"
        d="m 1822.5226,2396.9492 a 145.04354,145.04354 0 0 0 -145.0372,145.0445 145.04354,145.04354 0 0 0 145.0372,145.0447 145.04354,145.04354 0 0 0 145.0447,-145.0447 145.04354,145.04354 0 0 0 -145.0447,-145.0445 z m 0,29.1999 a 115.84016,115.84016 0 0 1 115.8451,115.8446 115.84016,115.84016 0 0 1 -115.8451,115.8377 115.84016,115.84016 0 0 1 -115.8377,-115.8377 115.84016,115.84016 0 0 1 115.8377,-115.8446 z"
      >
        <desc id="desc19">{"Objective IDs: \r\n494\r\n496\r\n578"}</desc>
      </path>
      <path
        id="path819"
        d="m 1383.3658,392.07761 a 200.88069,200.88069 0 0 0 -200.8714,200.88265 200.88069,200.88069 0 0 0 200.8714,200.88253 200.88069,200.88069 0 0 0 200.8823,-200.88253 200.88069,200.88069 0 0 0 -200.8823,-200.88265 z m 0.011,15.47218 a 185.41067,185.41067 0 0 1 185.409,185.41047 185.41067,185.41067 0 0 1 -185.4103,185.41039 185.41067,185.41067 0 0 1 -185.4107,-185.41039 185.41067,185.41067 0 0 1 185.4107,-185.41047 z"
      >
        <desc id="desc20">{"Objective IDs: \r\n504\r\n505\r\n506"}</desc>
      </path>
      <path
        id="path820"
        d="m 1243.8122,649.98201 a 44.685099,44.685099 0 0 0 -44.6835,44.68547 44.685099,44.685099 0 0 0 44.6835,44.68549 44.685099,44.685099 0 0 0 44.6855,-44.68549 44.685099,44.685099 0 0 0 -44.6855,-44.68547 z m 0,8.99597 a 35.688099,35.688099 0 0 1 35.6896,35.6895 35.688099,35.688099 0 0 1 -35.6896,35.68744 35.688099,35.688099 0 0 1 -35.6874,-35.68744 35.688099,35.688099 0 0 1 35.6874,-35.6895 z"
      >
        <desc id="desc21">{"Objective IDs: \r\n522"}</desc>
      </path>
      <path
        id="path821"
        d="m 2281.7234,1785.2935 a 44.685099,44.685099 0 0 0 -44.6832,44.6855 44.685099,44.685099 0 0 0 44.6832,44.6855 44.685099,44.685099 0 0 0 44.6855,-44.6855 44.685099,44.685099 0 0 0 -44.6855,-44.6855 z m 0,8.9959 a 35.688099,35.688099 0 0 1 35.6896,35.6896 35.688099,35.688099 0 0 1 -35.6896,35.6873 35.688099,35.688099 0 0 1 -35.6874,-35.6873 35.688099,35.688099 0 0 1 35.6874,-35.6896 z"
      >
        <desc id="desc22">{"Objective IDs: \r\n523"}</desc>
      </path>
      <path
        id="path822"
        d="m 2332.1446,1909.3454 a 44.685099,44.685099 0 0 0 -44.6832,44.6853 44.685099,44.685099 0 0 0 44.6832,44.6858 44.685099,44.685099 0 0 0 44.6851,-44.6858 44.685099,44.685099 0 0 0 -44.6851,-44.6853 z m 0,8.9961 a 35.688099,35.688099 0 0 1 35.6895,35.6892 35.688099,35.688099 0 0 1 -35.6895,35.6876 35.688099,35.688099 0 0 1 -35.6874,-35.6876 35.688099,35.688099 0 0 1 35.6874,-35.6892 z"
      >
        <desc id="desc23">{"Objective IDs: \r\n524"}</desc>
      </path>
      <path
        id="path823"
        d="m 3186.9338,1171.8335 a 44.685099,44.685099 0 0 0 -44.6835,44.6855 44.685099,44.685099 0 0 0 44.6835,44.6856 44.685099,44.685099 0 0 0 44.6854,-44.6856 44.685099,44.685099 0 0 0 -44.6854,-44.6855 z m 0,8.996 a 35.688099,35.688099 0 0 1 35.6895,35.6895 35.688099,35.688099 0 0 1 -35.6895,35.6874 35.688099,35.688099 0 0 1 -35.6874,-35.6874 35.688099,35.688099 0 0 1 35.6874,-35.6895 z"
      >
        <desc id="desc24">{"Objective IDs: \r\n540"}</desc>
      </path>
      <path
        id="path824"
        d="m 2439.5231,1362.1797 c -120.8902,0.011 -236.8272,48.0332 -322.3073,133.5178 -85.4803,85.4843 -133.5024,201.4236 -133.5015,322.3138 0,120.8904 48.0212,236.8304 133.5015,322.3149 85.4801,85.4847 201.417,133.5124 322.3073,133.5174 120.8943,0 236.8378,-48.0232 322.3233,-133.5088 85.4854,-85.4853 133.51,-201.4288 133.5092,-322.3235 0,-120.8943 -48.0238,-236.8372 -133.5092,-322.3223 -85.4855,-85.4854 -201.4291,-133.5102 -322.3233,-133.5093 z m 0.011,37.4232 a 418.40875,418.40875 0 0 1 418.4083,418.4084 418.40875,418.40875 0 0 1 -418.4083,418.4092 418.40875,418.40875 0 0 1 -418.4092,-418.4092 418.40875,418.40875 0 0 1 418.4092,-418.4084 z"
      >
        <desc id="desc25">{"Objective IDs: \r\n551"}</desc>
      </path>
      <path
        id="path825"
        d="m 1074.3847,1357.3003 a 29.878669,29.878669 0 0 0 -29.8774,29.8787 29.878669,29.878669 0 0 0 29.8774,29.8787 29.878669,29.878669 0 0 0 29.8789,-29.8787 29.878669,29.878669 0 0 0 -29.8789,-29.8787 z m 0,6.0148 a 23.86283,23.86283 0 0 1 23.8637,23.8639 23.86283,23.86283 0 0 1 -23.8637,23.8622 23.86283,23.86283 0 0 1 -23.8623,-23.8622 23.86283,23.86283 0 0 1 23.8623,-23.8639 z"
      >
        <desc id="desc26">{"Objective IDs: \r\n552"}</desc>
      </path>
      <path
        id="path826"
        d="m 1062.913,1172.9563 a 29.878669,29.878669 0 0 0 -29.8773,29.879 29.878669,29.878669 0 0 0 29.8773,29.879 29.878669,29.878669 0 0 0 29.879,-29.879 29.878669,29.878669 0 0 0 -29.879,-29.879 z m 0,6.0154 a 23.86283,23.86283 0 0 1 23.8639,23.8636 23.86283,23.86283 0 0 1 -23.8639,23.8625 23.86283,23.86283 0 0 1 -23.8621,-23.8625 23.86283,23.86283 0 0 1 23.8621,-23.8636 z"
      >
        <desc id="desc27">{"Objective IDs: \r\n553"}</desc>
      </path>
        {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default WoodsMap