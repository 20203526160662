import React from 'react';
import './KeysModal.css';


function KeysModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="keysModal">
      <div className="keysModalHeader">
        <span>Keys</span>
      </div>
      <div className="keysModalContent">
        {/* Keys modal specific content here */}
      </div>
    </div>
  );
}

export default KeysModal;



