import React, {useEffect, useRef, useState} from 'react';
import './QuestsModalListitem.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import TooltipPortal from '../TooltipPortal/TooltipPortal';
import {Tooltip} from 'react-tooltip';
import {useQuests} from "../../contexts/QuestContext"
import {useMap} from "../../contexts/MapContext";
import {useModal} from "../../contexts/ModalContext";

function QuestsModalListItem({ quest, handleButtonClick, hiddenPaths, selectedMap }) {
  const [isTooltipHovered] = useState(false);
  const { handleCompleteQuest } = useQuests();
  const { setHighlightedPaths, setHiddenPaths } = useMap();
  const { openModal } = useModal();
  const { reactTooltipRef } = useMap();
  const listItemRef = useRef(null); // Ref for the <li> element
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const openTooltip = () => setIsTooltipOpen(true);
  const closeTooltip = () => setIsTooltipOpen(false);


  const completeQuest = () => {
    handleCompleteQuest(quest.QuestId).then(() => {
      // Retrieve SVG paths from the quest's objectives
      const questSVGPaths = quest.objectives
        .filter(objective => objective.SVGPath) // Ensure the objective has an SVGPath
        .map(objective => objective.SVGPath); // Extract the SVGPath

      // Update hiddenPaths to include these SVG paths
      setHiddenPaths((prevState) => {
        // Ensure prevState is an array and remove duplicates
        const currentState = Array.isArray(prevState) ? prevState : [];
         // Combine and deduplicate
        return [...new Set([...currentState, ...questSVGPaths])];
      });
    });
  };


  const handleMouseEnter = () => {
    // Filter objectives based on selectedMap before highlighting paths
    const svgPaths = quest.objectives
      .filter((objective) => objective.SVGPath && objective.ObjectiveMap === selectedMap)
      .map((objective) => objective.SVGPath);
    setHighlightedPaths(svgPaths);
    openTooltip();
  };

  const handleMouseLeave = () => {
    if (!isTooltipHovered) {
      setHighlightedPaths([]);
      closeTooltip();
    }
  };

  // Function to handle mouse enter on an objective to highlight its path
  const handleObjectiveMouseEnter = (SVGPath) => {
    if (SVGPath) {
      setHighlightedPaths([SVGPath]);
    }
  };

  // Function to handle mouse leave on an objective to clear highlighted paths
  const handleObjectiveMouseLeave = () => {
    setHighlightedPaths([]);
  };

  const handleQuestTouch = () => {
    // Toggle the tooltip visibility
    setIsTooltipOpen(prev => !prev);
    // Perform any additional actions such as highlighting paths
    handleMouseEnter(); // Or a modified version that suits touch interactions
  };


  const handleScreenshotClick = (objectiveDetails) => {
    if (objectiveDetails.Image) {
      const imageUrls = objectiveDetails.Image.split('|').map(imageName =>
        `${process.env.REACT_APP_API_URL}/static/icons/objectives/${imageName}`
      );
      openModal({ images: imageUrls, description: objectiveDetails.Description });
    }
  };

  const checkIfOutsideElements = (event) => {
    if (reactTooltipRef.current && event.target) {
      if (!reactTooltipRef.current.contains(event.target)) {
        setHighlightedPaths([]);
        closeTooltip();
      }
    }
  };

  const toggleObjectiveVisibility = (SVGPath) => {
    // Early return if SVGPath is not provided
    if (!SVGPath) return;

    setHiddenPaths((prevState) => {
      const currentState = Array.isArray(prevState) ? prevState : [];
      if (currentState.includes(SVGPath)) {
        return currentState.filter(path => path !== SVGPath);
      } else {
        return [...currentState, SVGPath];
      }
    });
  };


  useEffect(() => {
    // Add the global mouse move listener
    // document.addEventListener('mousemove', checkIfOutsideElements);
    document.addEventListener('touchstart', checkIfOutsideElements);

    return () => {
      // Clean up the listener
      // document.removeEventListener('mousemove', checkIfOutsideElements);
      document.removeEventListener('touchstart', checkIfOutsideElements);
    };
  }, []);

  useEffect(() => {
    // This effect runs when `isTooltipHovered` changes.
    if (!isTooltipHovered) {
      // If the tooltip is not hovered, reset highlighted paths.
      setHighlightedPaths([]);
    }
  }, [isTooltipHovered]); // Dependency array includes `isTooltipHovered` to run the effect on its change.


  // Filter objectives based on selectedMap before rendering
  const visibleObjectives = quest.objectives.filter((objective) => objective.ObjectiveMap === selectedMap);

  // Adjusting the logic to consider objectives without an SVGPath as hidden
  const areAllObjectivesConsideredHidden = visibleObjectives.every(objective =>
    !objective.SVGPath || hiddenPaths.includes(objective.SVGPath)
  );


  return (
    <li data-tooltip-id={quest.QuestName} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onTouchStart={handleQuestTouch}>
      <button onClick={() => handleButtonClick(quest)} className="questButton">
        <FontAwesomeIcon
          icon={areAllObjectivesConsideredHidden ? faEyeSlash : faEye}
          style={{
            width: '1.25em',
            color: visibleObjectives.every(objective => !objective.SVGPath) ? 'grey' : areAllObjectivesConsideredHidden ? 'rgb(255,0,0)' : 'inherit'
          }}
        />
      </button>
      <span ref={listItemRef}>{quest.QuestName}</span>
      <TooltipPortal>
        <Tooltip
          isOpen={isTooltipOpen}
          id={quest.QuestName}
          className="questsModalTooltip"
          classNameArrow="questsModalTooltipArrow"
          place='right-start'
          clickable={true}
          style={{
            backgroundColor: 'black',
            borderRadius: '5px',
            fontSize: '16px',
          }}
        >
          <div ref={reactTooltipRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className='heading'>{quest.QuestName}</div>
            <div className='subheading'>
              <span>Objectives</span>
            </div>
            {visibleObjectives.length > 0 ? (
              visibleObjectives.map((objective, i) => (
                <div key={i} className="objectiveItem"
                     onMouseEnter={() => handleObjectiveMouseEnter(objective.SVGPath)}
                     onMouseLeave={handleObjectiveMouseLeave}>
                  <button
                    className="questObjectiveVisibilityButton"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent any parent handlers from being executed
                      if(objective.SVGPath) { // Check if SVGPath exists
                        toggleObjectiveVisibility(objective.SVGPath);
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={objective.SVGPath && !hiddenPaths.includes(objective.SVGPath) ? faEye : faEyeSlash}
                      style={{ width: '1.25em', color: objective.SVGPath ? (hiddenPaths.includes(objective.SVGPath) ? 'rgb(255,0,0)' : 'inherit') : 'grey' }}
                    />
                  </button>
                  {/* Make sure the screenshot button is separate and does not wrap other elements that might cause propagation issues */}
                  <button
                    className={`screenshotButton ${!objective.Image ? 'screenshotButtonDisabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation(); // This should now only stop the propagation for this button
                      if (objective.Image) {
                        handleScreenshotClick(objective);
                      }
                    }}
                    disabled={!objective.Image} // This disables the button if there is no image, adding another layer of fail-safe
                    style={!objective.Image ? { pointerEvents: "none" } : {}}
                  >
                    📷
                  </button>
                  {objective.Description}
                </div>
              ))
            ) : (
              <div>No objectives available for this map.</div>
            )}
            <button
              className="completeQuestButton" // Add appropriate classes or styles
              onClick={completeQuest}
            >
              Complete Quest
            </button>
          </div>
        </Tooltip>
      </TooltipPortal>
    </li>
  );
}

export default QuestsModalListItem;
