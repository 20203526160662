import { useRef, useEffect } from 'react';
import { useMap } from '../contexts/MapContext'

const useSvgPathDarkening = () => {
  const svgRef = useRef(null);
  const { highlightedPaths } = useMap();

  const darkenOutsidePaths = (pathIds) => {
    const svg = svgRef.current;
    if (!svg) return;

    let mask = svg.querySelector('#darkenMask');
    if (!mask) {
      mask = document.createElementNS('http://www.w3.org/2000/svg', 'mask');
      mask.setAttribute('id', 'darkenMask');
      svg.prepend(mask);

      const maskRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      maskRect.setAttribute('width', '100%');
      maskRect.setAttribute('height', '100%');
      maskRect.setAttribute('fill', 'white');
      mask.appendChild(maskRect);
    }

    // Clear existing paths in the mask
    const existingPaths = mask.querySelectorAll('path');
    existingPaths.forEach(path => mask.removeChild(path));

    // Add new paths to the mask
    pathIds.forEach(pathId => {
      const pathToMask = svg.querySelector(`#${pathId}`);
      if (pathToMask) {
        const clonedPath = pathToMask.cloneNode(true);
        clonedPath.setAttribute('fill', 'black');
        mask.appendChild(clonedPath);
      }
    });

    // Manage the overlay
    let overlay = svg.querySelector('#darkenOverlay');
    if (!overlay) {
      overlay = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      overlay.setAttribute('id', 'darkenOverlay');
      overlay.setAttribute('width', '100%');
      overlay.setAttribute('height', '100%');
      overlay.setAttribute('fill', 'rgba(0, 0, 0, 0.9)');
      overlay.setAttribute('mask', 'url(#darkenMask)');
      svg.appendChild(overlay);
    }
  };


  const clearDarkenOverlay = () => {
    const svg = svgRef.current;
    if (!svg) return;

    let overlay = svg.querySelector('#darkenOverlay');
    if (overlay) {
      svg.removeChild(overlay);
    }

    let mask = svg.querySelector('#darkenMask');
    if (mask) {
      svg.removeChild(mask);
    }
  };

  useEffect(() => {
    if (highlightedPaths.length > 0) {
      darkenOutsidePaths(highlightedPaths);
    } else {
      clearDarkenOverlay()
    }
  }, [highlightedPaths]);

  return { svgRef, darkenOutsidePaths, clearDarkenOverlay };
};

export default useSvgPathDarkening;