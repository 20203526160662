import React, { createContext, useContext, useState } from 'react';
import { useHideout } from './HideoutContext';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { fetchUserHideout } = useHideout();

  // Function to fetch ALL user info using fetchUserInfoByUserId and fetchUserHideout
  const fetchUserInfo = async (userId) => {
    await fetchUserInfoByUserId(userId);
    await fetchUserHideout();
  }

  const fetchUserInfoByUserId = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/userInfo/${userId}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const data = await response.json();
        setUser({ ...user, ...data }); // Update user state with fetched data
      } else {
        console.error("Error fetching user info. Status:", response.status);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const updateLoyaltyLevels = async (updatedLevels,) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update/userInfo/rep/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedLevels)
      });
      if (response.ok) {
        const updatedUser = await response.json();
        setUser(prevUser => ({ ...prevUser, ...updatedUser }));
        fetchUserInfo(user.UserId)
      } else {
        console.error("Error updating user rep. Status:", response.status);
      }
    } catch (error) {
      console.error('Error updating user rep:', error);
    }
  };


  return (
    <UserContext.Provider value={{ user, setUser, isLoggedIn, setIsLoggedIn, fetchUserInfo, updateLoyaltyLevels }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);