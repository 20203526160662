import React from 'react';
import ReactDOM from 'react-dom';

const TooltipPortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('tooltip-portal')
  );
};

export default TooltipPortal;
