import React from 'react';
import './HideoutLevelIcon.css';
import oneIcon from '../../static/images/hideoutIcons/1.png';
import twoIcon from '../../static/images/hideoutIcons/2.png';
import threeIcon from '../../static/images/hideoutIcons/3.png';
import fourIcon from '../../static/images/hideoutIcons/4.png';

function HideoutLevelIcon({ level }) {
  // Convert level to a number
  level = Number(level);

  let icon;
  switch(level) {
    case 0:
      return null; // Do not render if the level is 0
    case 1:
      icon = oneIcon;
      break;
    case 2:
      icon = twoIcon;
      break;
    case 3:
      icon = threeIcon;
      break;
    case 4:
      icon = fourIcon;
      break;
    default:
      return null; // Do not render if the level is not 1-4
  }

  return (
    <div className="hideoutLevelIcon">
      <img src={icon} alt={`Level ${level}`} />
    </div>
  )
}


export default HideoutLevelIcon;
