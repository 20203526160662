import React, { createContext, useContext, useState } from 'react';

const HideoutContext = createContext();

export const HideoutProvider = ({ children }) => {
  const [hideout, setHideout] = useState({});


 // Function to get user hideout information
  const fetchUserHideout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/userHideout/get`, {
        method: 'GET',
        credentials: 'include'
      });
      if (response.ok) {
        const userHideout = await response.json();
        setHideout(userHideout);
      }
    } catch (error) {
      console.error('Error fetching user hideout:', error);
    }
  };


  const updateHideoutLevels = async (module, newLevel) => {
    // Optimistically update the frontend
    setHideout(prev => ({ ...prev, [module]: newLevel }));

    try {
      const updatedLevels = { ...hideout, [module]: newLevel };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/userHideout/update`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedLevels)
      });
      if (!response.ok) {
        throw new Error("Backend update failed");
      }
      // No need to update hideout here if the optimistic update suffices
    } catch (error) {
      console.error('Error updating user hideout:', error);
    }
  };




  return (
    <HideoutContext.Provider value={{ hideout, setHideout, updateHideoutLevels, fetchUserHideout }}>
      {children}
    </HideoutContext.Provider>
  );
};

export const useHideout = () => useContext(HideoutContext);
