import React, { useState } from 'react';
import './QuestList.css';
import QuestCard from "../QuestCard/QuestCard";
import { useUser } from '../../contexts/UserContext';
import { useQuests } from '../../contexts/QuestContext';
import QuestListHeader from "../QuestListHeader/QuestListHeader";
import KartaButton from "../KartaButton/KartaButton";

function QuestList({ className, setIsMapSelectionOpen }) {
  const { user } = useUser();
  const { quests, questDetails } = useQuests();
  const [sortCriteria, setSortCriteria] = useState({ field: 'trader', order: 'asc' });
  const [locationFilter, setLocationFilter] = useState('');
  const handleSortChange = (field) => {
    setSortCriteria(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  const handleLocationFilterChange = (location) => {
    setLocationFilter(location); // Set to empty string to remove filter
  };

  const applySortingAndFiltering = (quests, questDetails) => {
    let filteredQuests = quests.filter(questId => {
      // Get the quest detail for the given questId
      const questDetail = questDetails[questId];

      // If there is a location filter, check the objectives
      if (locationFilter && questDetail && Array.isArray(questDetail.objectives)) {
        // Check if any of the objectives include the location filter
        return questDetail.objectives.some(objective =>
          objective.ObjectiveMap && objective.ObjectiveMap.includes(locationFilter));
      }
      // If there is no location filter, include all quests
      return true;
    });

    filteredQuests.sort((a, b) => {
      const questDetailA = questDetails[a];
      const questDetailB = questDetails[b];

      // Ensure quest details exist for both a and b
      if (!questDetailA || !questDetailB) return 0;

      // Sort by task (QuestName)
      if (sortCriteria.field === 'task') {
        if (sortCriteria.order === 'asc') {
          return (questDetailA.QuestName || "").localeCompare(questDetailB.QuestName || "");
        } else {
          return (questDetailB.QuestName || "").localeCompare(questDetailA.QuestName || "");
        }
      }

      // Sort by trader (GivenBy)
      if (sortCriteria.field === 'trader') {
        if (sortCriteria.order === 'asc') {
          return (questDetailA.GivenBy || "").localeCompare(questDetailB.GivenBy || "");
        } else {
          return (questDetailB.GivenBy || "").localeCompare(questDetailA.GivenBy || "");
        }
      }

      return 0; // Return 0 if no sorting criteria matched
    });
    return filteredQuests;
  };

  const sortedAndFilteredQuests = applySortingAndFiltering(quests, questDetails);

  return (
    <div className={`questListContainer ${className}`}>
      <QuestListHeader
        onSortChange={handleSortChange}
        sortCriteria={sortCriteria}
        onLocationFilterChange={handleLocationFilterChange}
        locationFilter={locationFilter}
      />
      <div className="questListScroll">
        {sortedAndFilteredQuests.map(questId => (
          <QuestCard
            key={questId}
            questId={questId}
            userId={user.userId}
          />
        ))}
      </div>
    </div>
  );
}

export default QuestList;

