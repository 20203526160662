import React, { useEffect, useRef, useCallback } from "react";
import "./GroundZeroMap.css";
import groundZeroMapImage from "../../static/images/maps/GroundZero.png";
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";


const GroundZeroMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();


  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${groundZeroMapImage})`,
    backgroundSize: "cover",
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div
      className="groundZeroMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width="8000"
        height="8000"
        viewBox="0 0 8000 8000" // Must match W x H here and in groundZeroMapContainer in CSS
      >
        {/* region paths */}
        <path
          id="path900"
          d="m 5649.0219,4164.05 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc314"> Objective IDs: 591</desc>
        </path>
        <path
          id="path901"
          d="m 4929.171,5458.5459 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc1"> Objective IDs: 592</desc>
        </path>
        <path
          id="path902"
          d="m 4618.6758,1803.6804 a 214.09372,214.09372 0 0 1 -214.0867,-214.0933 214.09372,214.09372 0 0 1 214.0936,-214.0936 214.09372,214.09372 0 0 1 214.0937,214.0936 214.09372,214.09372 0 0 1 -214.0937,214.0933 z m 0,-43.1004 a 170.98603,170.98603 0 0 0 170.9933,-170.9929 170.98603,170.98603 0 0 0 -170.9933,-170.9859 170.98603,170.98603 0 0 0 -170.9856,170.9859 170.98603,170.98603 0 0 0 170.9856,170.9929 z"
        >
          <desc id="desc2"> Objective IDs: 594</desc>
        </path>
        <path
          id="path903"
          d="m 6473.9154,3929.2488 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc3"> Objective IDs: 596</desc>
        </path>
        <path
          id="path904"
          d="m 6307.083,2264.0143 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc4"> Objective IDs: 597</desc>
        </path>
        <path
          id="path905"
          d="m 6937.3388,3138.3397 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc5"> Objective IDs: 598</desc>
        </path>
        <path
          id="path906"
          d="m 7070.1868,3555.4207 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc6"> Objective IDs: 599</desc>
        </path>
        <path
          id="path907"
          d="m 5040.3925,2977.6862 a 166.20657,166.20657 0 0 1 -166.2011,-166.2062 166.20657,166.20657 0 0 1 166.2065,-166.2065 166.20657,166.20657 0 0 1 166.2065,166.2065 166.20657,166.20657 0 0 1 -166.2065,166.2062 z m 0,-33.4599 a 132.74094,132.74094 0 0 0 132.7466,-132.7463 132.74094,132.74094 0 0 0 -132.7466,-132.7409 132.74094,132.74094 0 0 0 -132.7406,132.7409 132.74094,132.74094 0 0 0 132.7406,132.7463 z"
        >
          <desc id="desc7"> Objective IDs: 601 602</desc>
        </path>
        {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default GroundZeroMap;
