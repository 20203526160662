import React from 'react';
import ReactDOMServer from 'react-dom/server';
import './NeededKeys.css';
import { Tooltip } from 'react-tooltip';
import { useQuests } from "../../contexts/QuestContext";

function NeededKeys({ className }) {
  const { keyInfo, questDetails } = useQuests();

  const getQuestNameElementsByIds = (questIds) => {
    return questIds.map(questId => (
      <p key={questId} style={{ color: '#9F9F9FFF' }}>
        {questDetails[questId]?.QuestName || 'Unknown Quest'}
      </p>
    ));
  };

  return (
      <div className={`neededKeysContainer ${className}`}>
        <h2>Needed Keys</h2>
        <div className="keysScrollContainer">
          <div className="keysList">
            {Object.entries(keyInfo).map(([keyName, { keyInfo, quests }]) => {
              if (!keyInfo || !keyInfo.KeyId || !keyInfo.ImagePath) return null;

              const tooltipId = `tooltip-${keyInfo.KeyId}`;
              const tooltipHtmlContent = ReactDOMServer.renderToStaticMarkup(
                <div>
                  <p style={{ fontWeight: 'bold' }}>{keyInfo.KeyName}</p>
                  {getQuestNameElementsByIds(quests)}
                </div>
              );

              return (
                <div key={keyInfo.KeyId} className="keyItem">
                  <img
                    data-tooltip-id={tooltipId}
                    data-tooltip-html={tooltipHtmlContent}
                    src={`${process.env.REACT_APP_API_URL}/static/icons/keys/${keyInfo.ImagePath}`}
                    alt={keyInfo.KeyName}
                  />
                  <Tooltip
                    id={tooltipId}
                    className="custom-tooltip"
                    style={{
                      backgroundColor: 'black',
                      borderRadius: '5px solid white',
                      '--rt-opacity': 1,
                      fontSize: '16px',
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
  );
}

export default NeededKeys;
