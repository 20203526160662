/**
 * Extracts the first 'd' attribute up to the first 'z' character (inclusive) from an SVG path.
 * This is used to get the outer circle path of an SVG element.
 * @param {string} originalPathD - The original 'd' attribute of the SVG path.
 * @returns {string} - The modified 'd' attribute.
 */
export const getOuterCirclePathD = (originalPathD) => {
  const firstZIndex = originalPathD.toLowerCase().indexOf("z");
  return originalPathD.substring(0, firstZIndex + 1);
};

/**
 * Creates and appends invisible paths to the SVG for better interaction handling.
 * @param {HTMLElement} svgElement - The SVG container element.
 * @param {string[]} allSvgPaths - An array of all SVG paths IDs.
 * @param {string[]} hiddenPaths - An array of SVG paths IDs that should be hidden.
 * @param {Object} svgPathToObjectiveMap - Mapping of SVG path IDs to their respective objective information.
 * @param {Function} onInteraction - Callback for when an interaction occurs.
 * @param {Function} onLeave - Callback for when the mouse leaves an element.
 */
export const appendInvisiblePathsForInteraction = (svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, onInteraction, onLeave) => {
  const paths = svgElement.querySelectorAll("path");
  paths.forEach((path) => {
    if (Object.keys(svgPathToObjectiveMap).includes(path.id)) { // Check if path ID is in svgPathToObjectiveMap
      const newPathD = getOuterCirclePathD(path.getAttribute("d"));
      const newElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
      newElement.setAttribute("d", newPathD);
      newElement.setAttribute("fill", "none");
      newElement.setAttribute("pointer-events", "visibleFill");
      newElement.style.opacity = 0; // Make the path invisible

      // Adjust the visibility based on hiddenPaths and allSvgPaths
      if (hiddenPaths.includes(path.id)) {
        path.style.opacity = 0;
      } else {
        path.style.opacity = allSvgPaths.includes(path.id) ? 1 : 0;
      }

      // Set up interaction handlers
      newElement.addEventListener("mouseenter", (e) => onInteraction(e, path));
      newElement.addEventListener("touchstart", (e) => onInteraction(e, path));
      newElement.addEventListener("mouseleave", (e) => onLeave(e, path));

      svgElement.appendChild(newElement);
    }
  });
};

/**
 * Cleans up by removing dynamically added paths to the SVG.
 * @param {HTMLElement} svgElement - The SVG container element.
 */
export const cleanupSvg = (svgElement) => {
  svgElement.querySelectorAll('path[fill="none"]').forEach((el) => el.remove());
};
