import React, {useEffect} from 'react';
import './MapMarkerTooltip.css';
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import TooltipPortal from "../TooltipPortal/TooltipPortal";
import { useModal } from '../../contexts/ModalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useMap } from "../../contexts/MapContext"; // Ensure this is imported


const MapMarkerTooltip = ({ isOpen, position, objectiveDetails, setHoveringStatus }) => {
  const { openModal } = useModal();
  const { hiddenPaths, setHiddenPaths } = useMap(); // Assuming useMap provides these
  const { tooltipRef } = useMap();

  useEffect(() => {
    const handleMouseEnter = () => setHoveringStatus(true);
    const handleMouseLeave = () => setHoveringStatus(false);

    const tooltipElement = tooltipRef.current;
    if (tooltipElement) {
      tooltipElement.addEventListener('mouseenter', handleMouseEnter);
      tooltipElement.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (tooltipElement) {
        tooltipElement.removeEventListener('mouseenter', handleMouseEnter);
        tooltipElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [setHoveringStatus, tooltipRef]);

  useEffect(() => {
    const stopPropagation = (e) => {
      e.stopPropagation();
    };

    // Apply stopPropagation to the current tooltip reference
    const current = tooltipRef.current;
    if (current) {
      current.addEventListener('touchstart', stopPropagation, { passive: true });
    }

    return () => {
      if (current) {
        current.removeEventListener('touchstart', stopPropagation);
      }
    };
  });

  const handleScreenshotClick = () => {
    const imageUrls = objectiveDetails.Image.split('|').map(imageName =>
      `${process.env.REACT_APP_API_URL}/static/icons/objectives/${imageName}`
    );
    openModal({ images: imageUrls, description: objectiveDetails.Description });
  };

  const toggleObjectiveVisibility = () => {
    setHiddenPaths((prevState) => {
      const currentState = Array.isArray(prevState) ? prevState : [];
      const objectivePath = objectiveDetails.SVGPath; // Assuming each objectiveDetails has a SVGPath
      if (currentState.includes(objectivePath)) {
        return currentState.filter(path => path !== objectivePath);
      } else {
        return [...currentState, objectivePath];
      }
    });
  };

  // Determine if the current objective's path is hidden
  const isObjectiveVisible = !hiddenPaths.includes(objectiveDetails.SVGPath);

  return (
    <TooltipPortal>
      <div
        ref={tooltipRef}
        style={{ position: 'absolute', top: position.y, left: position.x, zIndex: 1050 }}
        onMouseEnter={() => setHoveringStatus(true)}
        onMouseLeave={() => setHoveringStatus(false)}
      >
        <Popover className="mapMarkerTooltip" isOpen={isOpen} placement="top">
          <PopoverTrigger>
            <div></div>
          </PopoverTrigger>
          <PopoverContent className="mapMarkerTooltipContent" bg="black">
            <PopoverArrow bg="black" />
            <PopoverHeader className="mapMarkerTooltipHeader">
              {objectiveDetails.questName}
            </PopoverHeader>
            <PopoverBody style={{ paddingTop: '0' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start', gap: '5px' }}> {/* Adjust gap as needed */}
                {/* Visibility Toggle Button */}
                <button onClick={toggleObjectiveVisibility} className="objectiveVisibilityButton">
                  <FontAwesomeIcon
                    icon={isObjectiveVisible ? faEye : faEyeSlash}
                    style={{ fontSize: '1.2rem', color: isObjectiveVisible ? 'inherit' : 'rgb(255,0,0)' }}
                  />
                </button>

                {/* Camera Emoji */}
                {objectiveDetails.Image && (
                  <div className="mapMarkerTooltipScreenshot" onClick={handleScreenshotClick}>
                    📷
                  </div>
                )}

                {/* Objective Description */}
                <div className="mapMarkerTooltipDescription">
                  {objectiveDetails.Description}
                </div>
              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
    </TooltipPortal>
  );
};

export default MapMarkerTooltip;
