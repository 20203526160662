import React, {useCallback, useEffect, useRef} from 'react';
import './LabsMap.css';
import labsMapImage from '../../static/images/maps/Labs.png';
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";

const LabsMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();


  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);

  const containerStyle = {
    backgroundImage: `url(${labsMapImage})`,
    backgroundSize: 'cover',
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div
      className="labsMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width="3820"
        height="1980"
        viewBox="0 0 3819.9999 1980" // Must match W x H here and in labsMapContainer in CSS
      >
        {/* region paths */}
        <path
         id="path300"
         d="m 3121.1636,906.70415 a 35.661756,35.661756 0 0 0 -35.6606,35.66176 35.661756,35.661756 0 0 0 35.6618,35.66176 35.661756,35.661756 0 0 0 35.6617,-35.66176 35.661756,35.661756 0 0 0 -35.6617,-35.66176 z m 0,7.17936 a 28.481274,28.481274 0 0 1 28.4824,28.4824 28.481274,28.481274 0 0 1 -28.4824,28.48128 28.481274,28.481274 0 0 1 -28.4812,-28.48128 28.481274,28.481274 0 0 1 28.4812,-28.4824 z"
         >
          <desc
           id="desc6"> Objective IDs:
  200</desc>
        </path>
      <path
         id="path301"
         d="m 1981.2376,1413.0098 v 50.4051 h 74.2629 v -50.4051 z m 5.1775,5.0053 h 63.9098 v 40.3943 h -63.9098 z"
         >
        <desc
           id="desc2">Objective IDs:
  557</desc>
      </path>
      <path
         id="path302"
         d="m 1686.3705,1707.2051 v 125.5078 h 65.6959 v -125.5078 z m 3.0345,3.5293 h 59.6269 v 118.4492 h -59.6269 z"
         >
        <desc
           id="desc4">Objective IDs:
  558</desc>
      </path>
      <path
         id="path303"
         d="m 3009.0899,648.19922 v 177.73242 h 142.5658 V 648.19922 Z m 12.5803,12.35547 h 117.4073 v 153.02148 h -117.4073 z"
         >
        <desc
           id="desc5">Objective IDs:
  559</desc>
      </path>
      <path
         id="path304"
         d="m 3026.1973,741.3418 v 38.01904 H 3133.123 V 741.3418 Z m 5.9414,5.20462 h 95.0429 v 27.60794 h -95.0429 z"
         >
        <desc
           id="desc7">Objective IDs:
  586</desc>
      </path>
        {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default LabsMap;
