import React, { useEffect, useState } from 'react';
import { useQuests } from '../../contexts/QuestContext';
import './ResetQuest.css';

function ResetQuest({ onCloseResetQuest = () => {} }) {
  const { fetchCompletedQuestsAndDetails, completedQuestDetails } = useQuests();
  const [questNames, setQuestNames] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [resetTrigger, setResetTrigger] = useState(false); // New state to control refresh
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { refreshQuests } = useQuests();

  // Fetch quests when the component mounts and data hasn't been fetched
  useEffect(() => {
    if (!isDataFetched) {
      setIsLoading(true);
      fetchCompletedQuestsAndDetails()
        .then(() => {
          setIsDataFetched(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isDataFetched]);

  useEffect(() => {
    const quests = Object.values(completedQuestDetails)
      .map(detail => ({
        name: detail.QuestName,
        id: detail.QuestId
      }))
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort quests alphabetically by name

    setQuestNames(quests);
  }, [completedQuestDetails]);


  const handleResetClick = (quest) => {
    setSelectedQuest(quest);
    setShowConfirmation(true);
  };

  const handleConfirmReset = async () => {
    if (selectedQuest) {
      setIsLoading(true);
      const isSuccess = await handleResetIndividualQuest(selectedQuest.id);
      if (isSuccess) {
        setSuccessMessage(`${selectedQuest.name} has been reset successfully.`); // Set success message
        refreshQuests();
        setResetTrigger(true);
      } else {
        setSuccessMessage(''); // Clear success message on failure
      }
      setShowConfirmation(false);
      setIsLoading(false);
    }
  };

  // Refresh the quest list when resetTrigger changes
  useEffect(() => {
    if (resetTrigger) {
      fetchCompletedQuestsAndDetails();
      setResetTrigger(false); // Reset the trigger after fetching
    }
  }, [resetTrigger]);


  const handleResetIndividualQuest = async (questId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset/single/${questId}`, {
        method: 'POST',
        credentials: 'include'
      });

      if (response.ok) {
        console.log(`QuestId: ${questId} reset successfully.`);
        return true; // Indicate success
      } else {
        console.error(`Error resetting quest. Status: ${response.status}`);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error(`Error resetting quest: ${error.message}`);
      return false; // Indicate failure
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleClose = () => {
    onCloseResetQuest();
  };

  const renderQuestsList = () => {
    if (isLoading) {
      return <div>Loading...</div>; // Replace with your loading indicator
    }
    if (questNames.length === 0) {
      return <div>No completed quests</div>; // Display message when no quests are available
    }
    return questNames.map((quest, index) => (
      <li key={index}>
        <span className="completedQuestName">{quest.name}</span>
        <button
          className="resetQuestButton"
          onClick={() => handleResetClick(quest)}
        >
          Reset
        </button>
      </li>
    ));
  };


  return (
    <>
      {showConfirmation && (
        <div className="confirmationOverlay">
          <div className="confirmationPopup">
            <div className="confirmationPopupContent">
              <p>Resetting a quest that is part of a quest chain will also reset any completed quests further in the chain than the quest you selected. Do you want to reset {selectedQuest?.name}?</p>
              <div className="buttonContainer">
                <button className="resetButton" onClick={handleConfirmReset}>Reset</button>
                <button className="cancelButton" onClick={handleCloseConfirmation}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="resetQuestContainer">
        <button className="resetQuestCloseButton" onClick={handleClose}>x</button>
        <h2>Completed Quests</h2>
        {successMessage && <div className="successMessage">{successMessage}</div>}
        <div className="resetQuestListContainer">
          <ul className="resetQuestList">
            {renderQuestsList()}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ResetQuest;
