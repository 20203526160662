import { useState } from 'react';
import { useQuests } from "../contexts/QuestContext";

const useTooltip = () => {
  const [isTouched, setIsTouched] = useState(false);
  const [tooltipInfo, setTooltipInfo] = useState({
    isOpen: false,
    x: 0,
    y: 0,
    objectiveDetails: null,
  });
  const { svgPathToObjectiveMap } = useQuests();

  const showTooltip = (pathId, x, y) => {
    if (!svgPathToObjectiveMap.hasOwnProperty(pathId)) {
      console.warn(`No data found for pathId: ${pathId}`);
      return; // Early return to avoid accessing undefined
    }
    const objectiveInfo = svgPathToObjectiveMap[pathId];
    setTooltipInfo({
      isOpen: true,
      x: x,
      y: y,
      objectiveDetails: {
        questName: objectiveInfo?.questName,
        Description: objectiveInfo?.Description,
        Image: objectiveInfo?.Image,
        SVGPath: objectiveInfo?.SVGPath,
      },
    });
  };


  const hideTooltip = () => {
    setTooltipInfo((prev) => ({ ...prev, isOpen: false }));
    setIsTouched(false);
  };

  const setHoveringStatus = (isHovering) => {
    setTooltipInfo(prev => ({ ...prev, isHovering }));
  };

  return { tooltipInfo, showTooltip, hideTooltip, setHoveringStatus, isTouched, setIsTouched };
};

export default useTooltip;
