import React, { useState } from 'react';
import './Login.css';
import { useUser } from '../../contexts/UserContext';

function Login({ handleCreateAccountClick }) {
  const { setUser, setIsLoggedIn, fetchUserInfo } = useUser();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false); // New state for Caps Lock status
  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  const handleCapsLock = (e) => {
    if (e.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });

      if (response.ok) {
        const responseData = await response.json();
        setUser({ userId: responseData.userId });
        setIsLoggedIn(true); // Set isLoggedIn in UserContext
        fetchUserInfo(responseData.userId);
      } else {
        // Display a user-friendly error message
        setErrorMessage('Login failed. Please check your username and password.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred while trying to log in.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="loginWindow">
      {errorMessage && <div className="error">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleCapsLock}
            onKeyUp={handleCapsLock}
          />
        </label>
        {isCapsLockOn && <div className="capsLockWarning">Caps Lock is on</div>} {/* Display Caps Lock warning */}
        <button type="submit" disabled={isLoading}>{isLoading ? 'Logging in...' : 'Login'}</button>
        <div className="accountPrompt">
          Don't have an account? <span className="signupLink" onClick={handleCreateAccountClick}> Sign Up</span>
        </div>
      </form>
    </div>
  );
}

export default Login;
