import React from 'react';
import './LoyaltyLevelIcon.css';
import oneIcon from '../../static/images/LLicons/1.png';
import twoIcon from '../../static/images/LLicons/2.png';
import threeIcon from '../../static/images/LLicons/3.PNG';
import fourIcon from '../../static/images/LLicons/4.PNG';

function LoyaltyLevelIcon({ level }) {
  // Convert level to a number
  const numericLevel = Number(level);

  let icon;
  switch(numericLevel) {
    case 1:
      icon = oneIcon;
      break;
    case 2:
      icon = twoIcon;
      break;
    case 3:
      icon = threeIcon;
      break;
    case 4:
      icon = fourIcon;
      break;
    default:
      icon = oneIcon;  // Default icon
  }

  return (
    <div className="loyaltyLevelIcon">
      <img src={icon} alt={numericLevel.toString()} />
    </div>
  )
}


export default LoyaltyLevelIcon;
