import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useUser} from './UserContext';
import {useMap} from './MapContext';

const QuestContext = createContext();

export const QuestProvider = ({ children }) => {
  const { user } = useUser();
  const { allSvgPaths, svgPaths, setSvgPaths, selectedMap } = useMap();
  const [quests, setQuests] = useState([]);
  const [questDetails, setQuestDetails] = useState({});
  const [keyInfo, setKeyInfo] = useState({});
  const [fetchedQuestIds, setFetchedQuestIds] = useState(new Set());
  const [fetchedKeyQuestIds, setFetchedKeyQuestIds] = useState(new Set()); // Add this state to track fetched quests for keys
  const [itemInfo, setItemInfo] = useState({});
  const [completedQuests, setCompletedQuests] = useState([]);
  const [completedQuestDetails, setCompletedQuestDetails] = useState({});

  const fetchQuestDetails = async (quest) => {
    const questDetailResponse = await fetch(`${process.env.REACT_APP_API_URL}/quest/${quest}`, {
      method: 'GET',
      credentials: 'include'
    });
    return questDetailResponse.json();
  };

  const fetchObjectiveDetails = async (objectiveId) => {
    const objectiveDetailResponse = await fetch(`${process.env.REACT_APP_API_URL}/objective/${objectiveId}`, {
      method: 'GET',
      credentials: 'include'
    });
    return objectiveDetailResponse.json();
  };

  const fetchQuestsAndDetails = async () => {
    try {
      const questResponse = await fetch(`${process.env.REACT_APP_API_URL}/availableQuests/unsorted`, {
        method: 'GET',
        credentials: 'include'
      });
      const questData = await questResponse.json();
      setQuests(questData.message);


      const updatedQuestDetails = Object.fromEntries(
        Object.entries(questDetails)
          .filter(([questId, _]) => questData.message.includes(Number(questId)))
      );
      setQuestDetails(updatedQuestDetails)


      let detailedQuests = { ...updatedQuestDetails };

      // Fetch details for each quest in parallel
      await Promise.all(questData.message.map(async (quest) => {
        if (!fetchedQuestIds.has(quest)) {
          const questDetailData = await fetchQuestDetails(quest);
          let questDetail = { ...questDetailData, objectives: [] };

          // Fetch objectives in parallel
          const objectiveResponse = await fetch(`${process.env.REACT_APP_API_URL}/objectiveId/${quest}`, {
            method: 'GET',
            credentials: 'include'
          });
          const objectiveData = await objectiveResponse.json();

          const objectiveDetails = await Promise.all(objectiveData.map(fetchObjectiveDetails));

          for (const objectiveDetailData of objectiveDetails) {
            if (objectiveDetailData.QuestId === questDetailData.QuestId) {
              questDetail.objectives.push(objectiveDetailData);
            } else {
              console.log(`Mismatch in QuestId for quest ${quest}`);
            }
          }

          detailedQuests[quest] = questDetail;
          fetchedQuestIds.add(quest);
        }
      }));

      setQuestDetails(detailedQuests);
      setFetchedQuestIds(new Set(fetchedQuestIds));
    } catch (error) {
      console.error('Error fetching quests and details:', error);
    }
  };

  const handleCompleteQuest = async (questId) => {
    const requestData = { questId: String(questId) };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/completeQuest`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(requestData),
      });

      if (response.status === 204 || response.status === 201) {
        console.log('Quest completed successfully');
        refreshQuests();
      } else {
        console.error('Error completing quest. Status:', response.status);
      }
    } catch (error) {
      console.error('Error completing quest:', error);
    }
  };



  const updateMapContext = () => {
  // Extract SVGPaths from questDetails and objectives
  const svgPathsToUpdate = Object.values(questDetails).flatMap((questDetail) => {
    // Assuming objectives is an array of objective objects in questDetail
    const objectives = questDetail?.objectives || [];
    // Extract SVGPath from each objective
    return objectives.map((objective) => {
      return objective?.SVGPath || null;
    }).filter((svgPath) => svgPath && typeof svgPath === 'string' && svgPath.trim().length > 0);
  }).flat();

  // Set the map context using updateSvgPaths
  setSvgPaths(svgPathsToUpdate);
};



  const fetchCompletedQuestsAndDetails = async () => {
    try {
      const completedQuestsResponse = await fetch(`${process.env.REACT_APP_API_URL}/userInfo/completeQuests`, {
        method: 'GET',
        credentials: 'include'
      });
      const completedQuestsData = await completedQuestsResponse.json();
      const validQuestIds = new Set(completedQuestsData.filter(questId => questId !== 0));

      let updatedCompletedQuestDetails = {};

      // Retain details for quests that are still completed and fetch new details
      for (const questId of validQuestIds) {
        if (completedQuestDetails[questId]) {
          updatedCompletedQuestDetails[questId] = completedQuestDetails[questId];
        } else {
          const questDetail = await fetchQuestDetails(questId);
          updatedCompletedQuestDetails[questId] = questDetail;
        }
      }

      setCompletedQuestDetails(updatedCompletedQuestDetails);
      setCompletedQuests(Array.from(validQuestIds));
    } catch (error) {
      console.error('Error fetching completed quests and details:', error);
    }
  };



  const fetchItemDetails = async (itemId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/items/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ItemId: itemId }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch item details');
    }
    const data = await response.json();
    let itemInfo = typeof data.message === 'string' ? JSON.parse(data.message) : data.message;
    itemInfo.Count = parseInt(itemInfo.Count, 10) || 0;
    return itemInfo;
  };

  const processItemInfo = (itemInfo, itemToQuestsMap) => {
    let itemName = itemInfo.ItemName;
    if (!itemName) return;

    if (!itemToQuestsMap[itemName]) {
      itemToQuestsMap[itemName] = { itemInfo: itemInfo, quests: [], combinedCount: 0 };
    }
    itemToQuestsMap[itemName].quests.push(itemInfo.QuestName);
    itemToQuestsMap[itemName].combinedCount += parseInt(itemInfo.Count, 10) || 0;
  };

  const fetchItemsForLevelsAhead = async (levelsAhead) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/quest/items/levelsAhead/${levelsAhead}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch items for levels ahead');
    }

    return response.json();
  };


  const fetchAllItemInfo = async (showLevelsAhead, levelsAhead = 0) => {
    let itemToQuestsMap = {};

    if (showLevelsAhead) {
      try {
        const data = await fetchItemsForLevelsAhead(levelsAhead);
        // Assuming data is an array of items. Adjust based on the actual structure.
        for (const itemString of data.message) {
          let itemInfo = typeof itemString === 'string' ? JSON.parse(itemString) : itemString;
          processItemInfo(itemInfo, itemToQuestsMap);
        }
      } catch (error) {
        console.error(`Error processing items for levels ahead:`, error);
      }
    } else {
      // Existing logic for when checkbox is not checked, unchanged.
      for (const quest of quests) {
        if (questDetails[quest]) {
          let itemIds = questDetails[quest].ItemRequired;
          itemIds = typeof itemIds === 'string' ? itemIds.split('|').filter(id => id.trim() !== '') : itemIds;

          for (const itemId of itemIds) {
            if (isNaN(itemId) || itemId === 'N') {
              continue; // Skip invalid itemId
            }
            try {
              const itemInfo = await fetchItemDetails(itemId);
              processItemInfo(itemInfo, itemToQuestsMap);
            } catch (error) {
              console.error(`Error fetching details for item ID ${itemId}:`, error);
            }
          }
        }
      }
    }

    setItemInfo(itemToQuestsMap);
  };


  const fetchAllKeyInfo = async () => {
    let newFetchedKeyQuestIds = new Set([...fetchedKeyQuestIds]);
    let filteredKeyInfo = {};

    let keyToQuestsMap = {}; // New structure to map keys to quests

  for (const quest of quests) {
    if (questDetails[quest]) {
      let keyIds = questDetails[quest].KeyRequired;
      keyIds = typeof keyIds === 'string' ? keyIds.split('|').filter(id => id.trim() !== '') : keyIds;

      for (const keyId of keyIds) {
          if (isNaN(keyId) || keyId === 'N') {
            continue; // Skip invalid keyId
          }

          const keyInfoResponse = await fetch(`${process.env.REACT_APP_API_URL}/key/info/${keyId}`);
          if (keyInfoResponse.ok) {
            const keyInfoData = await keyInfoResponse.json();
            let keyInfo = typeof keyInfoData.message === 'string' ? JSON.parse(keyInfoData.message) : keyInfoData.message;
            let keyName = keyInfo?.KeyName;
        if (keyName) {
          if (!keyToQuestsMap[keyName]) {
            keyToQuestsMap[keyName] = { keyInfo: keyInfo, quests: [] };
          }
          keyToQuestsMap[keyName].quests.push(quest);
        }
      }
    }

        newFetchedKeyQuestIds.add(quest);
      } else if (keyInfo[quest]) {
        filteredKeyInfo[quest] = keyInfo[quest]; // Keep existing key info for active quests
      }
    }

    setKeyInfo(keyToQuestsMap); // Update key information state with filtered and new key info
    setFetchedKeyQuestIds(newFetchedKeyQuestIds); // Update state of fetched quests
  };

  const currentMapId = selectedMap; // This should come from your map selection logic

  const svgPathToObjectiveMap = useMemo(() => {
    const map = {};
    Object.values(questDetails).forEach((quest) => {
      quest.objectives.forEach((objective) => {
        if (objective.SVGPath && objective.ObjectiveMap === currentMapId) { // Filter based on ObjectiveMap matching currentMapId
          map[objective.SVGPath] = { ...objective, questName: quest.QuestName };
        }
      });
    });
    return map;
  }, [questDetails, currentMapId]); // Include currentMapId in dependencies



  useEffect(() => {
    if (user) {
      fetchQuestsAndDetails();
    }
  }, [user]);

  useEffect(() => {
    if (quests.length > 0 && Object.keys(questDetails).length > 0) {
      fetchAllItemInfo();
    }
  }, [quests, questDetails]); // Depend on both quests and questDetails

  useEffect(() => {
      fetchAllKeyInfo();
      updateMapContext();
  }, [questDetails]);

  const refreshQuests = () => {
    fetchQuestsAndDetails(); // Re-fetch data when needed
  };

  return (
    <QuestContext.Provider value={{ quests, questDetails, keyInfo, itemInfo, fetchAllItemInfo, refreshQuests, handleCompleteQuest, completedQuests, completedQuestDetails, fetchCompletedQuestsAndDetails, svgPathToObjectiveMap }}>
      {children}
    </QuestContext.Provider>
  );
};

export const useQuests = () => useContext(QuestContext);