import React, { createContext, useState, useContext, useMemo, useRef } from 'react';

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [svgPaths, setSvgPaths] = useState({});
  const [highlightedPaths, setHighlightedPaths] = useState([]);
  const [hiddenPaths, setHiddenPaths] = useState([]);
  const [hoveredQuestObjectives, setHoveredQuestObjectives] = useState([]);
  const [selectedMap, setSelectedMap] = useState(null);
  const tooltipRef = useRef(null)
  const reactTooltipRef = useRef(null)

  const allSvgPaths = useMemo(() => {
    return Object.values(svgPaths).flat();
  }, [svgPaths, setSvgPaths]);

  return (
    <MapContext.Provider value={{ allSvgPaths, svgPaths, setSvgPaths, highlightedPaths, setHighlightedPaths, hiddenPaths, setHiddenPaths, hoveredQuestObjectives, setHoveredQuestObjectives, tooltipRef, reactTooltipRef, selectedMap, setSelectedMap }}>
      {children}
    </MapContext.Provider>
  );
};

export const useMap = () => useContext(MapContext);
