import React, {useCallback, useEffect, useRef} from 'react';
import './InterchangeMap.css';
import interchangeMapImage from '../../static/images/maps/Interchange.png';
import { useMap } from "../../contexts/MapContext";
import { useQuests } from "../../contexts/QuestContext";
import { appendInvisiblePathsForInteraction, cleanupSvg } from "../../utilities/svgUtilities";
import useSvgPathDarkening from "../../hooks/useSvgPathDarkening";
import MapMarkerTooltip from "../MapMarkerTooltip/MapMarkerTooltip";
import useTooltip from "../../hooks/useTooltip";

const InterchangeMap = ({ zoomLevel = 1, position = { x: 0, y: 0 } }) => {
  const { svgRef } = useSvgPathDarkening();
  const containerRef = useRef();
  const tooltipRef = useRef(null);
  const { allSvgPaths, hiddenPaths } = useMap();
  const { svgPathToObjectiveMap } = useQuests();
  const { tooltipInfo, isTouched, setIsTouched, showTooltip, hideTooltip, setHoveringStatus } = useTooltip();

  const handleInteraction = useCallback((e, path) => {
    e.preventDefault();
    e.stopPropagation();
    let clientX, clientY;

    if (e.touches && e.touches.length > 0) {
      setIsTouched(true);
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    } else {
      setIsTouched(false);
      clientX = e.clientX;
      clientY = e.clientY;
    }

    showTooltip(path.id, clientX, clientY);
  }, [setIsTouched, showTooltip]); // Add any dependencies here

  const handleLeave = useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);



  useEffect(() => {
    const handleTouchInside = (e) => {
      // Prevent the touch event from propagating to the document
      e.stopPropagation();
    };

    const currentTooltip = tooltipRef.current;
    if(currentTooltip) {
      // Add event listener to the tooltip
      currentTooltip.addEventListener('touchstart', handleTouchInside, { passive: true });
    }

    return () => {
      if(currentTooltip) {
        // Clean up the event listener
        currentTooltip.removeEventListener('touchstart', handleTouchInside);
      }
    };
  }, []);


  useEffect(() => {
    // Only initiate the hide sequence if the tooltip is not being hovered or touched
    if (!tooltipInfo.isHovering && !isTouched) {
      const timeoutId = setTimeout(() => {
        if (!tooltipInfo.isHovering && !isTouched) {
          hideTooltip();
        }
      }, 200); // Use a delay to allow for quick transitions between elements

      return () => clearTimeout(timeoutId);
    }
  }, [tooltipInfo.isHovering,isTouched, hideTooltip]);



  useEffect(() => {
    const handleCloseTouchOutside = (e) => {
      if (tooltipInfo.isOpen && !tooltipRef.current.contains(e.target)) {
        hideTooltip();
      }
    };

    document.addEventListener('touchstart', handleCloseTouchOutside, { passive: true });
    return () => {
      document.removeEventListener('touchstart', handleCloseTouchOutside);
    };
  }, [tooltipInfo.isOpen, hideTooltip]); // Assuming tooltipRef is stable and doesn't need to be a dependency


  useEffect(() => {
    const svgElement = svgRef.current;
    appendInvisiblePathsForInteraction(svgElement, allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave);

    return () => {
      cleanupSvg(svgElement); // Use svgElement here in the cleanup
    };
  }, [allSvgPaths, hiddenPaths, svgPathToObjectiveMap, handleInteraction, handleLeave, svgRef]);


  const containerStyle = {
    backgroundImage: `url(${interchangeMapImage})`,
    backgroundSize: "cover",
    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
  };

  return (
    <div
      className="interchangeMapContainer"
      style={containerStyle}
      ref={containerRef}
    >
      <svg
        ref={svgRef}
        width="4227"
        height="2385"
        viewBox="0 0 4227 2385" // Must match W x H here and in interchangeMapContainer in CSS
      >
        {/* region paths */}
        <path
         id="path200"
         d="m 1811.2751,959.70492 v 95.65838 h 171.5603 v -95.65838 z m 6.585,7.10047 h 158.3903 v 81.45951 h -158.3903 z"
         >
        <desc
           id="desc2">Objective IDs:
  148</desc>
        </path>
        <path
         id="path201"
         d="m 1810.9285,1102.8462 v 110.5617 h 171.9069 v -110.5617 z m 6.5983,8.2067 h 158.7103 v 94.1507 h -158.7103 z"
         >
        <desc
           id="desc3">Objective IDs:
  149</desc>
        </path>
        <path
         id="path202"
         d="m 1953.0151,1346.6132 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc53"> Objective IDs:
  174</desc>
        </path>
        <path
         id="path203"
         d="m 2097.7321,1517.6758 a 33.452988,33.452988 0 0 0 -33.452,33.453 33.452988,33.452988 0 0 0 33.453,33.453 33.452988,33.452988 0 0 0 33.453,-33.453 33.452988,33.452988 0 0 0 -33.453,-33.453 z m 0,6.7347 a 26.71724,26.71724 0 0 1 26.7182,26.7183 26.71724,26.71724 0 0 1 -26.7182,26.7173 26.71724,26.71724 0 0 1 -26.7173,-26.7173 26.71724,26.71724 0 0 1 26.7173,-26.7183 z"
         >
        <desc
           id="desc4"> Objective IDs:
  175</desc>
        </path>
        <path
         id="path204"
         d="m 2041.7616,1642.8991 v 94.4454 h 137.5946 v -94.4454 z m 5.2813,7.0105 h 127.0321 v 80.4264 h -127.0321 z"
         >
        <desc
           id="desc5">Objective IDs:
  279</desc>
        </path>
        <path
         id="path205"
         d="m 2039.7639,959.9605 v 111.0185 h 94.3529 V 959.9605 Z m 7.542,7.54021 h 79.269 v 95.94019 h -79.269 z"
         >
        <desc
           id="desc9">Objective IDs:
  280</desc>
        </path>
        <path
         id="path206"
         d="m 1660.7988,739.137 v 73.35651 h 111.7619 V 739.137 Z m 4.2898,5.44506 h 103.1823 v 62.46798 h -103.1823 z"
         >
        <desc
           id="desc10">Objective IDs:
  281</desc>
        </path>
        <path
         id="path207"
         d="m 1563.0912,847.7168 v 240.4176 h 53.6725 V 847.7168 Z m 5.2387,5.44614 h 43.193 v 229.52526 h -43.193 z"
         >
        <desc
           id="desc12">Objective IDs:
  282</desc>
        </path>
        <path
         id="path208"
         d="m 1566.7253,1454.2897 v 240.4176 h 49.9964 v -240.4176 z m 4.8799,5.4461 h 40.2346 v 229.5253 h -40.2346 z"
         >
        <desc
           id="desc13">Objective IDs:
  283</desc>
        </path>
        <path
         id="path209"
         d="m 1992.2272,178.09338 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc14"> Objective IDs:
  285</desc>
        </path>
        <path
         id="path210"
         d="m 2241.714,754.02072 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc15"> Objective IDs:
  286</desc>
        </path>
        <path
         id="path211"
         d="m 1813.8122,2122.5221 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc16"> Objective IDs:
  287</desc>
        </path>
        <path
         id="path212"
         d="m 1620.2026,2009.2972 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc1"> Objective IDs:
  288</desc>
        </path>
        <path
         id="path213"
         d="m 627.64696,1092.2249 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc6"> Objective IDs:
  289</desc>
        </path>
        <path
         id="path214"
         d="m 743.32258,1972.536 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc7"> Objective IDs:
  290</desc>
        </path>
        <path
         id="path215"
         d="m 800.16318,2059.1833 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc8"> Objective IDs:
  291</desc>
        </path>
        <path
         id="path216"
         d="m 1044.2753,578.05657 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc11"> Objective IDs:
  293</desc>
        </path>
        <path
         id="path217"
         d="m 422.27377,734.41472 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc17"> Objective IDs:
  294</desc>
        </path>
        <path
         id="path218"
         d="m 995.75036,195.24869 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86004,-31.86 31.86,31.86 0 0 0 -31.86004,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.44604,25.446 25.445,25.445 0 0 1 -25.44604,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc18"> Objective IDs:
  295</desc>
        </path>
        <path
         id="path219"
         d="m 530.84221,668.97957 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z M 2041.7616,1642.8991 v 94.4454 h 137.5946 v -94.4454 z m 5.2813,7.0105 h 127.0321 v 80.4264 h -127.0321 z">
        <desc
           id="desc20"> Objective IDs:
  306</desc>
        </path>
        <path
         id="path220"
         d="m 3388.6672,1495.1289 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z M 2118.196,814.79944 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z">
        <desc
           id="desc22"> Objective IDs:
  316</desc>
        </path>
        <path
         id="path221"
         d="m 3040.9149,801.31445 v 910.27775 h 646.7147 V 801.31445 Z m 10.2389,9.95797 h 626.2389 v 890.36188 h -626.2389 z m -871.6226,268.83718 v 394.0824 h 366.6314 v -394.0824 z m 7.3563,10.3706 h 351.92 v 373.3412 h -351.92 z m -560.2466,726.8164 v 533.2853 h 524.46 v -533.2853 z m 10.523,14.0338 h 503.4157 V 2336.548 H 1637.1639 Z M 1543.0137,801.31445 v 855.38085 h 634.9511 V 801.31445 Z m 10.0527,9.35743 h 614.8477 v 836.66602 h -614.8477 z m 22.2793,-641.08008 v 465.64453 h 610.7266 V 169.5918 Z m 12.2539,12.2539 h 586.2207 v 441.13672 h -586.2207 z"
         >
        <desc
           id="desc26">Objective IDs:
  318
  483
  484
  573</desc>
        </path>
        <path
         id="path222"
         d="m 1120.7383,157.99722 a 49.995584,49.995584 0 0 0 -49.994,49.99558 49.995584,49.995584 0 0 0 49.9956,49.99559 49.995584,49.995584 0 0 0 49.9956,-49.99559 49.995584,49.995584 0 0 0 -49.9956,-49.99558 z m 0,10.06502 a 39.928991,39.928991 0 0 1 39.9306,39.93056 39.928991,39.928991 0 0 1 -39.9306,39.929 39.928991,39.928991 0 0 1 -39.929,-39.929 39.928991,39.928991 0 0 1 39.929,-39.93056 z"
         >
        <desc
           id="desc27"> Objective IDs:
  537</desc>
        </path>
        <path
         id="path223"
         d="m 3295.2842,1108.7213 v 551.9102 h 387.2189 v -551.9102 z m 8.5649,7.8418 h 370.0912 v 536.2266 h -370.0912 z m -1494.7026,-15.6842 v 551.9102 h 365.6523 v -551.9102 z m 8.0879,7.8418 h 349.4785 v 536.2266 H 1817.2344 Z M 701.49609,1866.3828 v 216.9649 h 128.58399 v -216.9649 z m 6.06641,5.0254 h 116.45312 v 206.9121 H 707.5625 Z"
         >
        <desc
           id="desc29">Objective IDs:
  552</desc>
        </path>
        <path
         id="path224"
         d="m 2069.7936,1410.5778 a 33.452988,33.452988 0 0 0 -33.452,33.453 33.452988,33.452988 0 0 0 33.453,33.453 33.452988,33.452988 0 0 0 33.453,-33.453 33.452988,33.452988 0 0 0 -33.453,-33.453 z m 0,6.7347 a 26.71724,26.71724 0 0 1 26.7182,26.7183 26.71724,26.71724 0 0 1 -26.7182,26.7173 26.71724,26.71724 0 0 1 -26.7173,-26.7173 26.71724,26.71724 0 0 1 26.7173,-26.7183 z"
         >
        <desc
           id="desc30"> Objective IDs:
  553</desc>
        </path>
        <path
         id="path225"
         d="m 834.12891,2049.1322 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z"
         >
        <desc
           id="desc31"> Objective IDs:
  554</desc>
        </path>
        <path
         id="path226"
         d="m 584.32333,1052.0206 a 31.86,31.86 0 0 0 -31.859,31.86 31.86,31.86 0 0 0 31.86,31.86 31.86,31.86 0 0 0 31.86,-31.86 31.86,31.86 0 0 0 -31.86,-31.86 z m 0,6.414 a 25.445,25.445 0 0 1 25.446,25.446 25.445,25.445 0 0 1 -25.446,25.445 25.445,25.445 0 0 1 -25.445,-25.445 25.445,25.445 0 0 1 25.445,-25.446 z m -7.42489,14.2584 v 21.6624 h 14.03499 v -21.6624 z m 2.3119,2.6298 h 9.41119 v 16.401 h -9.41119 z">
        <desc
           id="desc32"> Objective IDs:
  555</desc>
        </path>
        {/* endregion */}
      </svg>
      {tooltipInfo.isOpen && (
        <div ref={tooltipRef}>
          <MapMarkerTooltip
            isOpen={tooltipInfo.isOpen}
            position={{ x: tooltipInfo.x, y: tooltipInfo.y }}
            objectiveDetails={tooltipInfo.objectiveDetails}
            setHoveringStatus={setHoveringStatus}
          />
        </div>
      )}
    </div>
  );
};

export default InterchangeMap;
