import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateAccount.css';
import FactionSelection from "../FactionSelection/FactionSelection";


function CreateAccount({ onAccountCreated, setShowCreateAccount }) {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        USEC: ''
    });


    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0) {
            if (onAccountCreated) {
                onAccountCreated(); // Redirect or perform the desired action
            }
        }
        return () => clearTimeout(timer);
    }, [countdown, onAccountCreated]);

    const handleFactionSelection = (usecValue) => {
        setFormData({
            ...formData,
            USEC: usecValue
        });
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

     const handleBackClick = () => {
        setShowCreateAccount(false);
    };

    // Validation function for username
    const validateUsername = (username) => {
        const regex = /^[a-zA-Z0-9]{5,15}$/;
        return regex.test(username);
    };

    // Validation function for password
    const validatePassword = (password) => {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, password } = formData;

        // Validate username and password
        if (!validateUsername(username)) {
            setError('Invalid username. Must be 5-15 characters and alphanumeric.');
            return;
        }

        if (!validatePassword(password)) {
            setError('Invalid password. Must be at least 8 characters and include uppercase, lowercase, and numeric characters.');
            return;
        }
        try {
            await axios.post(`${apiUrl}/userInfo/create`, formData);
            setSuccess(true);
            setError('');
            setCountdown(3);
        } catch (error) {
            setError(error.response?.data.error || 'Error creating user');
            setSuccess(false);
        }
    };

    return (
        <div className="createAccountWindow">
            <h2>Create an account</h2>
            {error && <p className="error">{error}</p>}
            {success &&
                <>
                    <p className="success">Account created successfully!</p>
                    <p className="success">Redirecting in {countdown}...</p>
                </>
            }
            <form onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input type="text" name="username" value={formData.username} onChange={handleChange} />
                </label>
                <label>
                    Password:
                    <input type="password" name="password" value={formData.password} onChange={handleChange} />
                </label>
                <label>
                    BEAR or USEC
                    <FactionSelection
                        currentFaction={null} onSelectionChange={handleFactionSelection} className="factionSelectionStyle" />
                </label>
                <button type="submit">Create Account</button>
            </form>
            <button onClick={handleBackClick}>Back</button>
        </div>
    );
}

export default CreateAccount;
