import ReactDOM from 'react-dom';

const ConfirmationPortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('confirmation-portal')
  );
};

export default ConfirmationPortal;
